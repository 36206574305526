<template>
  <!-- 图片预览 -->
  <div v-show="isShowImgPreviewDialog" class="dialog">
    <div class="dis-flex return-head">
      <a-icon type="arrow-left" class="return" @click="closePreviewDialog" />
      <div class="the-prompt">
        {{ productName }}
      </div>
      <div class="return-btns">
        <a-button @click="closePreviewDialog">Back</a-button>
      </div>
    </div>
    <div class="d-content" :style="{ height: screenHeight - 60 + 'px' }">
      <div class="d-img">
        <div class="d-image">
          <a-spin :spinning="loading">
            <img
              :src="preViewImg"
              :width="screenHeight - 60"
              :height="screenHeight - 60"
            >
          </a-spin>
        </div>
        <div v-if="gearList.length > 0" class="mode">
          <div class="title">Actual Effect Mode</div>
          <a-radio-group
            v-model="value"
            :default-value="gear"
            @change="onChangeGear()"
          >
            <a-radio
              v-for="(item, index) in gearList"
              :key="index"
              :value="index"
            >
              {{ item }}
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="preview-list">
        <ul class="clearfix" :style="{ height: screenHeight - 208 + 'px' }">
          <!-- :class="{ actived: pageStatu == 1 }" -->
          <!-- <li
          :class="{ actived: previewIndex == index }"
          v-for="(item, index) in previewList"
          :key="index"
        >
          <img :src="item" @click="changePreview(index)" />
        </li> -->
          <div class="pre-title">
            Views
          </div>
          <a-spin :spinning="loading">
            <li
              v-for="(item, index) in previewList"
              :key="index"
              :class="{ actived: index == previewIndex }"
            >
              <img :src="item" @click="changePreview(item, index)">
            </li>
          </a-spin>
        </ul>
        <div class="preview-save">
          <a-button
            type="primary"
            :loading="saveLoading"
            @click="save"
          >Save</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isShowImgPreviewDialog: {
      type: Boolean
    },
    previewList: {
      type: Array
    },
    preViewImg: {
      type: String
    },
    productName: {
      type: String
    },
    gearList: {
      type: Array
    },
    gear: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      value: 0,
      saveLoading: false,
      screenHeight: 0,
      previewIndex: 0
    }
  },
  watch: {
    loading() {
      this.saveLoading = this.loading
    }
  },
  mounted() {
    this.value = this.gear - 1
    this.screenHeight = document.body.offsetHeight
  },
  methods: {
    // 切换档位
    onChangeGear() {
      console.log(this.value)
      this.previewIndex = 0
      this.$emit('changeGear', this.value + 1)
    },
    changePreview(val, index) {
      this.$emit('changePreview', val)
      this.previewIndex = index
    },
    closePreviewDialog() {
      this.$parent.preview_fun()
    },
    save() {
      this.saveLoading = true
      this.$parent.savePreviewImage()
    }
  }
}
</script>
<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 100;
  .return-head {
    height: 60px;
    background: #f2f2f2;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    .return {
      font-size: 20px;
      cursor: pointer;
      color: #262626;
      margin-right: 15px;
    }
    .the-prompt {
      position: relative;
      width: 100%;
      color: #262626;
      flex: 1;
      border-radius: 4px;
      font-size: 18px;
    }
    .return-btns {
      margin-left: auto;
    }
  }
  .d-content {
    position: fixed;
    width: 100%;
    background: white;
    top: 60px;
    display: flex;
    .d-img {
      position: relative;
      text-align: center;
      // width: 76.5625%;
      flex: 1;
      display: flex;
      .d-image {
        flex: 1;
        text-align: center;
      }
      .mode {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        width: 180px;
        height: 240px;
        background: white;
        border-radius: 5px;
        margin-top: 30px;
        margin-right: 30px;
        margin-left: 30px;
        .title {
          font-size: 16px;
          padding-top: 24px;
        }
        .ant-radio-wrapper {
          margin-top: 19px;
          font-size: 14px;
          display: block;
          text-align: left;
        }
      }
    }
    .preview-list {
      margin-right: 30px;
      .preview-save {
        button {
          width: 420px;
          height: 44px;
          margin-top: 15px;
        }
      }
      ul {
        border: 1px solid #e6e6e6;
        overflow-y: scroll;
        width: 420px;
        margin-top: 30px;
        .pre-title {
          padding-top: 25px;
          padding-left: 20px;
          font-size: 16px;
          font-weight: bold;
        }
        li {
          float: left;
          cursor: pointer;
          border: solid 1px #e6e6e6;
          box-sizing: border-box;
          margin-left: 23px;
          height: 165px;
          margin-top: 20px;
          overflow: hidden;
          &.actived {
            border: solid 1px #2260b5;
            background-color: rgba(216, 216, 216, 0.12);
          }
          img {
            width: 165px;
            height: 165px;
          }
          // &.actived {
          //   background-color: rgba(216, 216, 216, 0.12);
          // }
        }
      }
    }
  }
}
</style>
