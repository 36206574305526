<template>
  <div>
    <div class="custom">
      <div class="dis-flex return-head">
        <a-icon type="arrow-left" class="return" @click="$router.go(-1)" />
        <div class="the-prompt">{{ productName }}</div>
        <div class="return-btns">
          <span v-if="currBlankInfo">
            <a-button
              v-if="currBlankInfo.effectTipImageUrl"
              style="margin-right: 20px"
              @click="showEff"
            >Tips</a-button>
          </span>
          <a-button
            v-for="(item, index) in patternInfos"
            :key="index"
            :type="item.isDefault ? 'primary' : ''"
            @click="changeMode(item.modeName)"
          >{{ item.modeName }}</a-button>
        </div>
      </div>
      <div class="content">
        <!-- 提交刀版图显示的加载中效果 -->
        <div v-if="submitAwait" class="await">
          <div class="await-left" />
          <div class="await-center">
            <a-spin size="large" class="aspin" />
          </div>
          <div class="await-right" />
        </div>
        <div class="canvas">
          <!-- 刀版列表 -->
          <Knife
            :show-image-list="showImageList"
            :curr-blank-info="currBlankInfo"
            :knife-plate-image-urls="knifePlateImageUrl"
            :window-height="windowHeight"
            :real-time-image="realTimeImage"
            :real-time-preview-loading="realTimePreviewLoading"
            @choice="choiceKnife"
          />
          <div
            ref="canvasDiv"
            style="width: 100%; position: relative; height: 100vh"
          >
            <div
              v-for="(item, index) in knifeLists"
              v-show="currKnifeId == item.id && !submitAwait"
              :key="index"
              class="canvas-box"
              :style="
                'margin: 0 auto;width:' +
                  windowsWidth +
                  'px;height:' +
                  windowsWidth +
                  'px;'
              "
            >
              <canvas :id="'canvas' + item.id" />
            </div>
            <Control
              v-show="!submitAwait"
              :knife-index="knifeIndex"
              :canvas="canvas"
              :scaling="scaling"
              :windows-width="windowsWidth"
              @real="KnifePreview"
              @isMirror="isMirror"
            />
          </div>
        </div>
        <!-- 控制器 -->
        <div class="control">
          <div class="control-li" @click="openUpload">
            <img src="../../assets/img/upload.svg">
            <span>Upload</span>
          </div>
          <div
            v-if="isShowPsd"
            class="control-li"
            @click="isShowPSDDialog = true"
          >
            <img src="../../assets/img/PSD.svg">
            <span>PSD</span>
          </div>
          <div class="control-li" @click="isBackgroundColorDialog = true">
            <img src="../../assets/img/changebg.svg">
            <span>Color</span>
          </div>
        </div>
        <div
          v-show="!isEditFont"
          class="edit"
          :style="'height: ' + (windowHeight - 35 - 31 - 100) + 'px'"
        >
          <div class="edit-tab">
            <div class="edit-tab-li" @click="changeDesignVariantsTab('design')">
              <span
                :class="{ actived: designVariantsActived == 'design' }"
              >Design Layer</span>
            </div>
            <div
              class="edit-tab-li"
              @click="changeDesignVariantsTab('variants')"
            >
              <span
                :class="{ actived: designVariantsActived == 'variants' }"
              >Variants</span>
            </div>
          </div>
          <div
            class="edit-content"
            :style="'height: ' + (windowHeight - 35 - 31 - 100 - 71) + 'px'"
          >
            <!-- 图片列表 -->
            <Imglist
              ref="refImgList"
              :design-variants-actived="designVariantsActived"
              :is-show-add-img-dialog="isShowAddImgDialog"
              :submit-await.sync="submitAwait"
              :loading.sync="loading"
              :oss-data="ossData"
              :knife-index="knifeIndex"
              :canvas="canvas"
              :canvas-height="canvasHeight"
              :windows-width="windowsWidth"
              :is-variants="isVariants"
              :knife-lists="knifeLists"
              :scaling="scaling"
              @real="mirrorChange"
              @closAddDialog="closAddImgDialog"
            />

            <SkuMask
              v-show="designVariantsActived == 'variants'"
              ref="refSku"
              :specifications="specifications"
              :select-sku-id-arr="selectSkuIdList"
            />
          </div>
        </div>
        <div class="submits">
          <a-button
            type="primary"
            class="submit"
            :loading="submitAwait"
            @click="save()"
          >Submit</a-button>
        </div>

      </div>

      <img v-show="hide" id="yulan">
      <img v-show="hide" id="qian">
      <!-- <canvas id="canvasRealTime"></canvas> -->

      <!-- loadding加载 -->
      <div v-show="loading" class="loading">
        <div class="loading-load">
          <a-spin size="large" tip="loading..." />
        </div>
      </div>
      <!-- 效果提示图 -->
      <div v-if="dialogEff" class="dialog-eff">
        <a-icon type="close-circle" theme="twoTone" @click="showEff" />
        <img
          :src="currBlankInfo.effectTipImageUrl"
          :style="
            'height:' + windowHeight + 'px;width:' + windowHeight * 2 + 'px'
          "
          mode
        >
      </div>
      <!-- 图片预览弹窗 -->
      <Preview
        v-if="isShowImgPreviewDialog"
        ref="preview"
        :product-name="productName"
        :is-show-img-preview-dialog="isShowImgPreviewDialog"
        :preview-list="previewList"
        :gear-list="gearList"
        :loading="isShowImgPreviewDialogLoading"
        :pre-view-img="preViewImg"
        :gear="gear"
        @changeGear="onChangeGear"
        @changePreview="onChangePreview"
      />
      <!-- 下载PSD弹窗 -->
      <PSD
        v-if="currBlankInfo"
        :is-show-p-s-d-dialog="isShowPSDDialog"
        :psd-file-url="currBlankInfo.psdFileUrl"
        :psd-size="currBlankInfo.psdFileSize"
        @close="closePSDDialog"
      />
      <!-- 设置背景颜色 -->
      <SetBackground
        v-show="isBackgroundColorDialog"
        @changeBgColor="changeColor"
        @real="KnifePreview"
      />
      <!-- 系统图片 -->
      <SysImage ref="SysImage" @add="addImg" />
      <!-- 提示 -->
      <Tips v-show="isTips" :product-id="productId" @close="onTipsClose" @open="onTipsOpen" />
    </div>
  </div>
</template>
<script>
const delIcon = require('../../assets/img/image-delete.svg') // 新增按钮
const rotateIcon = require('../../assets/img/image-spin.svg') // 旋转按钮
const zoomIcon = require('../../assets/img/image-enlarge.svg') // 缩放按钮
const bgIcon = require('../../assets/img/bg.svg') // 缩放按钮
console.log(bgIcon)
import uuid from 'licia/uuid'
import Knife from '@/components/fact/custom/knife'
import Preview from '@/components/fact/custom/preview'
import PSD from '@/components/fact/custom/downPSD'
import Control from '@/components/fact/custom/control'
import SysImage from '@/components/fact/custom/sysImage'
import SetBackground from '@/components/fact/custom/setBackground'
import Imglist from '@/components/fact/custom/imglist'
import SkuMask from '@/components/fact/custom/sku'
import Tips from '@/components/fact/custom/tips'
// import fontEdit from "@/components/fact/custom/fontEdit";
import OSS from 'ali-oss'
import { fileSts } from '../../api/oss'
import {
  generateKnifePreview,
  productDetail,
  productGenerate,
  generateStatus,
  generatePreview,
  generatePreviewStatus,
  previewSave
} from '../../api/product'
import { submitOssFile } from '../../api/file'
import { fabric } from 'fabric'
import 'fabric-customise-controls'
export default {
  components: {
    Knife,
    Preview,
    PSD,
    Control,
    SetBackground,
    SysImage,
    Imglist,
    SkuMask,
    Tips
  },
  filters: {
    optionNameFilter(v) {
      if (v) {
        var json = JSON.parse(v)
        return json.text
      }
      return v
    }
  },
  data() {
    return {
      // 刀版信息
      knifeLists: [],
      currKnifeId: 0,
      knifeIndex: 0,
      canvasList: [],
      // 刀版切图id列表
      knifeIdList: [],

      mode: '', // 模式切换
      previewId: '', // 预览ID

      windowsWidth: 0,
      windowHeight: 0,

      editArr: [],
      isEditFont: false, // 是否正在编辑字体

      designVariantsActived: 'design',
      proposalWidthHeight: 0, // 建议宽高
      knifePlateImageUrl: '',

      showImageList: [], // 展示图片列表
      imgUid: 1, // 每添加一张图片+1
      specifications: [],
      canvasHeight: 0,
      scaling: 0, // 缩放比例
      canvasWidth: 0,
      previewList: [],
      previewIndex: 0,
      preViewImg: '', // 预览图片主图
      factoryImageFileId: '', // 工厂图ID
      allChecked: [],
      // datas: { type: 3, token: "7cc4edc40b87d4427c17d2fa7889e03f" },
      loading: false, // Loading...
      blankProductId: 0, // 白板ID
      ossData: {},
      canvas: [], // 画布对象
      id: 0,

      knifeId: '', // 刀版ID
      productId: '', // 产品id
      productName: '', // 产品名称
      gear: 1, // 档位
      blankProductInfo: {},

      isBackgroundColorDialog: false, // 设置背景图片
      isShowImgPreviewDialog: false, // 图片预览遮罩层
      isShowImgPreviewDialogLoading: false, // 预览图片loading效果
      isRequestRealTime: true, // 是否需要实时请求
      isShowPSDDialog: false,
      isShowAddImgDialog: false, // 是否显示添加图片弹窗
      dialogEff: false, // 效果提示图
      hide: false,
      submitAwait: false, // 生成效果图，或者提交刀版图等待的loading
      isVariants: true,

      tempCheckpoint: 0,
      knifeHeight: 0, // 刀版图高度
      proportion: '', // 刀版图比例,
      previewReqData: {}, // 保存最近一次预览请求数据

      patternInfos: [], // 模式信息

      // 实时数据
      realTimeImage: [],
      realTimePreviewLoading: false,
      realknifeFileIdList: [],
      lastRealTimeList: [], // 记录实时预览最后一次操作
      hasMirrow: false,
      isTips: false
    }
  },
  computed: {
    isShowPsd() {
      let bool = false
      this.patternInfos.forEach(item => {
        if (item.psdFileUrl) {
          bool = true
        } else {
          bool = false
        }
      })
      return bool
    },
    gearList() {
      const arr = []
      for (let i = 0; i < 100; i++) {
        if (this.blankProductInfo['gearName' + i]) {
          arr.push(this.blankProductInfo['gearName' + i])
        }
      }
      return arr
    },
    // 获取所有的skuID
    selectSkuIdList() {
      const spec = this.specifications
      const arr = []
      // 一开始默认选中所有的sku
      for (let i = 0; i < spec.length; i++) {
        for (let j = 0; j < spec[i]['items'].length; j++) {
          spec[i]['items'][j]['ischeck'] = true
          arr.push(...spec[i]['items'][j]['ids'])
        }
      }
      var newArr = [...new Set(arr)]
      return newArr
    },
    // 当前正在编辑的刀版信息
    currBlankInfo() {
      var info = null
      const patternInfos = this.patternInfos
      patternInfos.forEach(item => {
        if (item.isDefault === true) {
          item.knifeInfos.forEach(item2 => {
            if (item2.checked) {
              this.currKnifeId = item2.id
            }
            info = Object.assign(item, item2)
          })
        }
      })
      console.log('currBlankInfo.....')
      console.log(info)
      return info
    },
    // 未完成有时间再整
    lists() {
      // const _this = this;
      this.canvasList.forEach(item => {
        console.log(item)
      })
      return true
    }
  },
  watch: {
    currKnifeId(val) {
      console.log('变化...')
      this.canvas.forEach((item, index) => {
        if (item.id === val) {
          this.knifeIndex = index
        }
      })
    }
  },
  async mounted() {
    console.log(fabric)
    const width = this.$refs.canvasDiv.offsetWidth
    const height = this.$refs.canvasDiv.offsetHeight - 170
    if (width < height) {
      this.windowsWidth = width - 80
    } else {
      this.windowsWidth = height
    }
    this.productId = this.$route.query.id
    this.knifeLists = JSON.parse(this.$route.query.patternInfos)
    await this.customMsg()
    await this.getOssMsg()
  },
  methods: {
    onTipsOpen() {
      this.isTips = true
    },
    onTipsClose() {
      this.isTips = false
    },
    // 获取产品详情
    async customMsg() {
      const { data, code } = await productDetail({
        id: this.productId,
        showImageList: true, // 展示图片列表
        blankProductInfo: true,
        skuInfos: true,
        skuMap: true,
        knifeList: true, // 刀版列表
        blankPattern: true
      })
      const skumap = data.skuMaps
      for (let i = 0; i < skumap.length; i++) {
        for (let j = 0; j < skumap[i].items.length; j++) {
          if (skumap[i].items[j].option) {
            skumap[i].items[j].option = JSON.parse(skumap[i].items[j].option)
          }
        }
      }
      this.specifications = skumap
      if (code === 200) {
        this.showImageList.push(data.showImageList[0])
        this.gear = data.blankProductInfo.defaultGear
        this.productName = data.blankProductInfo.model
        this.blankProductId = data.id
        this.blankProductInfo = data.blankProductInfo
        this.skuInfos = data.skuInfos
        data.patternInfos.forEach(item => {
          item.modeId = item.id
          item.modeName = item.name
          const param = {}
          param.modeId = item.id
          param.knifelist = []
          item.knifeInfos.forEach((item2, index2) => {
            const knifeParam = {}
            knifeParam.knifeId = item2.id
            knifeParam.knifeFileId = ''
            param.knifelist.push(knifeParam)
            this.knifeIdList.push(param)
            if (item.isDefault) {
              this.currKnifeId = item2.id
            }
            if (index2 === 0) {
              item2['checked'] = true
            } else {
              item2['checked'] = false
            }
          })
        })
        console.log('knifeIdList....')
        console.log(this.knifeIdList)
        this.knifeLists.forEach((item, index) => {
          this.initCanvas(item.id, index, item.url)
        })
        this.patternInfos = data.patternInfos
      }
    },
    // 修改模式
    changeMode(val) {
      this.knifeIndex = 0
      // this.$refs.refImgList.editArr = [];
      this.patternInfos.forEach((item, index) => {
        this.$set(this.patternInfos[index], 'isDefault', false)
        if (item.modeName === val) {
          this.$set(this.patternInfos[index], 'isDefault', true)
          item.knifeInfos.forEach(item2 => {
            if (item2.checked) {
              this.currKnifeId = item2.id
            }
          })
        }
      })
    },
    // 修改档位
    async onChangeGear(index) {
      var _this = this
      this.isShowImgPreviewDialogLoading = true
      this.gear = index
      var param = Object.assign({}, this.previewReqData)
      param.gear = index
      const { data } = await generatePreview(param)
      this.previewId = data.customProductGeneratePreviewId
      // 轮训
      let setInter = null
      const customProductGeneratePreviewId =
        data.customProductGeneratePreviewId
      await new Promise(function() {
        setInter = setInterval(async() => {
          const { data } = await generatePreviewStatus({
            customProductGeneratePreviewId: customProductGeneratePreviewId
          })
          if (data.status === 2) {
            clearInterval(setInter)
            _this.isShowImgPreviewDialogLoading = false
            _this.previewList = data.imageUrls
            _this.preViewImg = data.imageUrls[0]
            _this.$refs.preview.previewImage = data.imageUrls[0]
          } else if (data.status === 3) {
            clearInterval(setInter)
            _this.$message.error('Generate fail')
            _this.isShowImgPreviewDialogLoading = false
            _this.previewList = data.imageUrls
          }
        }, 500)
      })
    },
    // 图层sku切换
    changeDesignVariantsTab(val) {
      this.designVariantsActived = val
    },
    // 关闭图片预览
    closePreview() {
      this.previewIndex = 0
      this.isShowImgPreviewDialog = false
    },
    // 图片预览
    async preview_fun() {
      const _this = this
      const knifeIndex = this.knifeIndex
      const canvas = _this.canvas[knifeIndex]['canvas']
      const currBlankInfo = this.currBlankInfo.knifeInfos
      const knifeList = this.knifeLists
      this.$refs.refSku.getSelectSkuIdList()
      if (this.$refs.refSku.skutipArr !== '') {
        this.$message.warn(
          'Choose at least one [' + this.$refs.refSku.skutipArr + ']'
        )
        return
      }
      this.submitAwait = true
      if (this.isShowImgPreviewDialog === true) {
        this.previewIndex = 0
        this.isShowImgPreviewDialog = false
        for (let i = 0; i < knifeList.length; i++) {
          for (let j = 0; j < currBlankInfo.length; j++) {
            if (knifeList[i].id === currBlankInfo[j].id) {
              this.setCanvas(i, knifeList[i].url)
            }
          }
        }
        this.submitAwait = false
      } else {
        const obj = canvas.getObjects()
        if (obj.length < 1) {
          this.$message.warn(
            'No image detected, please upload at least 1 image first.'
          )
          this.submitAwait = false
          return
        }
        // const userEditImgList = [];
        await Promise.all([this.generateKnifeMsg()]).then(values => {
          _this.genterPreview(values[0])
        })
      }
    },
    // 定制所需的刀版信息
    async generateKnifeMsg() {
      const _this = this
      const arr = []
      const currBlankInfo = this.currBlankInfo.knifeInfos
      const knifeList = this.knifeLists
      for (let i = 0; i < knifeList.length; i++) {
        for (let j = 0; j < currBlankInfo.length; j++) {
          if (knifeList[i].id === currBlankInfo[j].id) {
            const userEditImgList = []
            const canvasfor = _this.canvas[i]['canvas']
            const getObject = canvasfor.getObjects()
            for (let j = 0; j < getObject.length; j++) {
              if (getObject[j].fileUrl && getObject[j].fileUrl != null) {
                userEditImgList.push(getObject[j].fileUrl)
              }
            }
            const effectImageSize = this.currBlankInfo.effectImageSize
            // 清空刀版图
            canvasfor.overlayImage = null
            canvasfor.renderAll.bind(canvasfor)

            var oldWidth = canvasfor.getWidth()
            var oldHeight = canvasfor.getHeight()
            let proportion = effectImageSize / this.windowsWidth
            proportion = Math.floor(proportion * 100) / 100
            // 将效果图画布等比例放大
            canvasfor.setWidth(effectImageSize)
            canvasfor.setHeight(effectImageSize)
            canvasfor.setZoom(proportion)
            await canvasfor.renderAll()
            var effectBase64 = canvasfor.toDataURL('image/png')
            document.getElementById('qian').src = effectBase64
            canvasfor.setWidth(oldWidth)
            canvasfor.setHeight(oldHeight)
            canvasfor.setZoom(1)
            const param = {}
            param.canvasJson = JSON.stringify(canvasfor)
            param.canvasSvg = canvasfor.toSVG()
            param.canvasHeight = canvasfor.getHeight()
            param.canvasWidth = canvasfor.getWidth()
            param.knifeId = currBlankInfo[j].id
            param.userImages = userEditImgList
            arr.push(param)
          }
        }
      }
      return arr
    },
    // 生成预览图
    async genterPreview(arr) {
      const _this = this
      console.log('this.currBlankInfo.modeId....' + this.currBlankInfo.modeId)
      const param = {
        patternId: this.currBlankInfo.modeId,
        blankProductId: this.blankProductId,
        knifeList: arr,
        selectSkuIdList: this.$refs.refSku.selectSkuIdList
      }
      if (this.gearList.length > 0) {
        param.gear = this.gear
      }
      this.previewReqData = Object.assign({}, param)
      const { data, code } = await generatePreview(param)
      if (code === 200) {
        this.previewId = data.customProductGeneratePreviewId
        let setInter = ''
        const customProductGeneratePreviewId =
          data.customProductGeneratePreviewId
        await new Promise(function(resolve) {
          console.log(resolve)
          setInter = setInterval(async() => {
            const { data } = await generatePreviewStatus({
              customProductGeneratePreviewId: customProductGeneratePreviewId
            })
            if (data.status === 2) {
              clearInterval(setInter)
              _this.loading = false
              _this.isShowImgPreviewDialog = true
              _this.previewList = data.imageUrls
              _this.preViewImg = data.imageUrls[0]
              _this.submitAwait = false
            } else if (data.status === 3) {
              clearInterval(setInter)
              _this.$message.error('Generate fail')
              _this.loading = false
              _this.previewList = data.imageUrls
              _this.isShowImgPreviewDialog = true
              _this.submitAwait = false
            }
          }, 500)
        })
      } else {
        _this.loading = false
        _this.isShowImgPreviewDialog = true
        _this.submitAwait = false
      }
    },
    // 保存刀版图
    async save() {
      const _this = this
      // const imgId = []; //刀版和效果图图片ID的集合
      // const knifeIndex = this.knifeIndex;
      this.$refs.refSku.getSelectSkuIdList()
      if (this.$refs.refSku.skutipArr !== '') {
        this.$message.warn(
          'Choose at least one [' + this.$refs.refSku.skutipArr + ']'
        )
        return
      }
      _this.submitAwait = true
      await Promise.all([this.generateKnifeMsg()]).then(async values => {
        // _this.genterKnife(values[0]);
        const param = {
          patternId: this.currBlankInfo.modeId,
          blankProductId: this.blankProductId,
          knifeList: values[0],
          selectSkuIdList: this.$refs.refSku.selectSkuIdList
        }
        if (this.gearList.length > 0) {
          param.gear = this.gear
        }
        this.previewReqData = Object.assign({}, param)
        const { data, code } = await productGenerate(param)
        if (code === 200) {
          _this.getGenerateStatus(data.customProductGenerateId)
        }
      })
    },
    // 图片预览那边的保存方法
    async savePreviewImage() {
      const param = {}
      const canvas = this.canvas[this.knifeIndex].canvas
      const getObject = canvas.getObjects()
      const userEditImgList = []
      for (let i = 0; i < getObject.length; i++) {
        if (getObject[i].fileUrl && getObject[i].fileUrl != null) {
          userEditImgList.push(getObject[i].fileUrl)
        }
      }
      param.canvasHeight = this.windowsWidth
      param.canvasJson = this.getCancanJSon()
      param.canvasSvg = canvas.toSVG()
      param.canvasWidth = this.windowsWidth
      param.previewId = this.previewId
      param.userImages = userEditImgList
      const { code, data, msg } = await previewSave(param)
      if (code === 200) {
        this.getGenerateStatus(data.customProductGenerateId)
      } else {
        this.$message.error(msg)
      }
    },
    // 提交刀版
    async postKnife(canvasSvg) {
      const _this = this
      const canvas = this.canvas[this.knifeIndex].canvas
      const getObject = canvas.getObjects()
      console.log(getObject)
      const userEditImgList = []
      for (let i = 0; i < getObject.length; i++) {
        if (getObject[i].fileUrl && getObject[i].fileUrl != null) {
          userEditImgList.push(getObject[i].fileUrl)
        }
      }
      const param = {
        blankProductId: this.blankProductId,
        // factoryImageFileId: this.factoryImageFileId,
        knifePlateFileId: this.knifeId,
        selectSkuIdList: this.selectSkuIdList,
        userImages: userEditImgList,
        canvasHeight: this.windowsWidth,
        canvasJson: this.getCancanJSon(),
        canvasSvg: canvasSvg || this.canvas.toSVG(),
        canvasWidth: this.windowsWidth
      }
      if (this.gearList.length > 0) {
        param.gear = this.gear
      }
      const { code, data } = await productGenerate(param)
      if (code === 200) {
        // 获取发布进度
        _this.getGenerateStatus(data.customProductGenerateId)
      }
    },
    async submitOSSFile2(file, fileType) {
      try {
        // 获取OSS配置信息
        const client = new OSS({
          endpoint: this.ossData.ossEndpoint,
          accessKeyId: this.ossData.accessKeyId,
          accessKeySecret: this.ossData.accessKeySecret,
          bucket: this.ossData.bucket,
          stsToken: this.ossData.securityToken
        })
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const uuidValue = uuid()
        const fileNames = `${this.ossData.ossObjectNamePrefix}${uuidValue}${suffix}`
        // 简单上传文件
        const res = await client.multipartUpload(fileNames, file, {
          progress: async function(p) {
            console.log(p)
          },
          headers: {
            'Cache-Control': 'max-age=360000'
          }
        })
        if (res.res.statusCode === 200) {
          const imageUrl = this.ossData.domain + '/' + fileNames
          const getImageInfoUrl = imageUrl + '?x-oss-process=image/info'
          const getImageInfoResponse = await fetch(getImageInfoUrl)
          const getImageInfoResult = await getImageInfoResponse.json()
          console.warn(getImageInfoResult)
          const imageWidth = getImageInfoResult.ImageWidth.value
          const imageHeight = getImageInfoResult.ImageHeight.value
          const fileSize = getImageInfoResult.FileSize.value
          const { data } = await submitOssFile({
            fileSize: fileSize,
            fileName: fileName,
            fileType: fileType,
            width: imageWidth,
            height: imageHeight,
            fileUrl: imageUrl,
            bucketName: this.ossData.bucket,
            objectName: fileNames
          })
          console.warn(data)
          return data
        } else {
          this.$message.error('Image upload failed due to unstable network')
        }
      } catch (error) {
        this.$message.error('Image upload failed due to unstable network')
      }
    },
    // 获取画布json
    getCancanJSon() {
      const canvas = this.canvas[this.knifeIndex].canvas
      return JSON.stringify(canvas)
    },
    // 获取画布svg
    getCancantoSVG() {
      const canvas = this.canvas[this.knifeIndex].canvas
      return canvas.toSVG()
    },
    // 获取发布进度
    async getGenerateStatus(id) {
      const _this = this
      for (;;) {
        const { data } = await generateStatus({ customProductGenerateId: id })
        if (data.status === 2) {
          _this.loading = false
          _this.callNewPage({
            path: '/mockups',
            query: {
              customProductId: data.customProductId,
              productId: _this.productId
            }
          })
          break
        } else if (data.status === 3) {
          _this.knifeLists.forEach((item, index) => {
            _this.setCanvas(index, item.url)
          })
          _this.$message.error('Build error')
          _this.submitAwait = false
          break
        }
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      }
    },

    // 获取OSS信息
    async getOssMsg() {
      const { data } = await fileSts()
      this.ossData = data
    },
    // 计算宽高
    countWidthHeight(e) {
      const bound = e.getBoundingRect()
      const paramPosi = {
        sizeHeight: bound.height,
        sizeWidth: bound.width
      }
      this.$refs.refImgList.setPosi(paramPosi)
      const param = {}
      param.height = bound.height
      param.width = bound.width
      return param
    },
    realTimeParam() {
      const _this = this
      const currBlankInfo = this.currBlankInfo
      console.log(currBlankInfo)
      const canvas = _this.canvas[this.knifeIndex]['canvas']
      const param = {
        blankProductId: _this.blankProductId,
        canvasHeight: canvas.getHeight(),
        canvasJson: JSON.stringify(canvas),
        canvasSvg: canvas.toSVG(),
        canvasWidth: canvas.getHeight(),
        knifeId: this.currKnifeId,
        patternId: _this.currBlankInfo.modeId
      }
      // console.log("ttttttttt..............");
      // console.log(param);
      return param
    },
    // 实时预览
    async KnifePreview() {
      // 生成刀版列表
      if (this.isRequestRealTime === true) {
        this.isRequestRealTime = false
        let param = null
        if (this.lastRealTimeList.length > 0) {
          param = this.lastRealTimeList[0]
        } else {
          param = this.realTimeParam()
        }
        this.lastRealTimeList.splice(0, 1)
        this.realTimePreviewLoading = true
        /*
        let { data } = await cutImage(param);
*/
        // 判断该刀版是否已经预览过
        let count = ''
        this.realknifeFileIdList.forEach((item, index) => {
          if (item.knifeId === this.currKnifeId) {
            count = index
          }
        })
        if (count === '') {
          const knifeListParm = {
            knifeId: this.currKnifeId,
            svg: param.canvasSvg
          }
          this.realknifeFileIdList.push(knifeListParm)
        } else {
          this.realknifeFileIdList[count].svg = param.canvasSvg
        }
        this.realtimePreview()
      } else {
        console.log('test')
        // 记录最后一次的实时预览操作
        let num = 0
        let index = ''
        const real = this.realTimeParam()
        this.lastRealTimeList.forEach((item, index2) => {
          if (item.knifeId === real.knifeId) {
            num = num + 1
            index = index2
          }
        })
        if (num === 0) {
          this.lastRealTimeList.push(real)
        } else {
          this.lastRealTimeList[index] = real
        }
      }
    },
    // 实时预览
    async realtimePreview() {
      const params = {
        blankProductId: this.blankProductId,
        knifeList: this.realknifeFileIdList,
        patternId: this.currBlankInfo.modeId
      }
      const { data } = await generateKnifePreview(params)
      this.realTimePreviewLoading = false
      // 先只展示第一张
      /* data.push(
        "https://wwyhangzhou.oss-cn-hangzhou.aliyuncs.com/merchantUpload/38/fd64f799e70e4e3fb4e3f4403b48f464.jpg"
      );*/
      this.showImageList = data
      // this.realTimeImage = data[0];
      console.log(data)
      this.isRequestRealTime = true
      if (this.lastRealTimeList.length > 0) {
        this.KnifePreview()
      }
    },
    // 进行最后操作记录的生成
    afterGenerateKnifePreview() {
      const _this = this
      generateKnifePreview(this.realPreviewparm).then(res => {
        _this.isRequestRealTime = true
        _this.realPreviewparm = null
        _this.showImageList = res.data.imageUrls
        console.log(res)
      })
    },
    // 设置画布
    setCanvas(index, knifeUrl) {
      const _this = this
      _this.imgUid = _this.imgUid + 1
      const canvas = this.canvas[index].canvas

      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = knifeUrl
      /*               image.set({
                id: originId,
                top: 300,
                left: 200,
                originX: 'center',
                originY: 'center',
                angle: 0,
                transparentCorners: false,
                cornerColor: 'blue',
                cornerStrokeColor: 'red',
                borderColor: 'red',
                cornerSize: 12,
                padding: 5,
                cornerStyle: 'circle',
                borderDashArray: [3, 3],

                overlayImageLeft: 1,
                overlayImageTop: 1,
                crossOrigin: "Anonymous",
                angle: 0, // 图片旋转角度
                opacity: 1, // 图片透明度
                scaleX: canvas.width / image.width,
                scaleY: canvas.height / image.height,
                imgUid: _this.imgUid
              }); */
      // 屏幕1000 刀版宽2000高4000
      image.onload = function() {
        const scaling =
          _this.currBlankInfo.knifePlateImageWidth / _this.windowsWidth
        _this.knifeHeight = image.height
        canvas.setWidth(_this.currBlankInfo.knifePlateImageWidth / scaling)
        canvas.setHeight(_this.currBlankInfo.knifePlateImageHeight / scaling)
        _this.canvasWidth = canvas.getWidth()
        _this.scaling = canvas.height / image.height
        // canvas.setBackgroundColor("#fd4a5f", canvas.renderAll.bind(canvas));
        canvas.setOverlayImage(knifeUrl, canvas.renderAll.bind(canvas), {
          overlayImageLeft: 1,
          overlayImageTop: 1,
          crossOrigin: 'Anonymous',
          angle: 0, // 图片旋转角度
          opacity: 1, // 图片透明度
          scaleX: canvas.width / image.width,
          scaleY: canvas.height / image.height,
          imgUid: _this.imgUid
        })
        return true
      }
    },
    mirrorChange() {
      this.KnifePreview()
      if (this.hasMirrow) {
        this.handleMirror()
      }
    },
    // 初始化画布
    async initCanvas(val, index, knifeUrl) {
      const _this = this
      // 设置背景图片

      const canvasParam = {
        id: val,
        canvas: new fabric.Canvas('canvas' + val)
      }
      canvasParam.canvas.controlsAboveOverlay = true

      this.canvas.push(canvasParam)
      this.setCanvas(index, knifeUrl)
      const canvas = this.canvas[index]['canvas']
      _this.canvasHeight = canvas.height
      this.windowHeight = document.body.offsetHeight
      canvas.on('object:scaling', () => {
        // this.KnifePreview();
      })
      canvas.on('object:scaled', e => {
        if (e.target.isFont) {
          return
        } else {
          const widthHeight = this.countWidthHeight(e.target)
          const paramPosi = {
            sizeHeight: widthHeight.height,
            sizeWidth: widthHeight.width
          }
          this.$refs.refImgList.setPosi(paramPosi)
        }
      })
      // 监听画布被旋转事件
      canvas.on('object:rotating', e => {
        // const actived = canvas.getActiveObject();
        const paramPosi = {
          rotate: e.target.angle
        }
        this.$refs.refImgList.setPosi(paramPosi)
      })
      // 监听画布的图层编辑事件
      canvas.on('mouse:down', e => {
        if (e.target == null) {
          return
        } else if (e.target.imgUid === 0) {
          return
        } /* else if (e.target.imgUid.toString().indexOf('mirror_copy_') > -1) {
          return;
        }*/
        const actived = canvas.getActiveObject()
        if (e.target.isFont) {
          this.isEditFont = true
          this.$refs.refImgList.setObject(actived.imgUid)
          return
        } else {
          this.isEditFont = false
          const widthHeight = this.countWidthHeight(e.target)
          if (actived) {
            const paramPosi = {
              rotate: actived.angle,
              sizeHeight: widthHeight.height,
              sizeWidth: widthHeight.width,
              posiX: actived.left,
              posiY: actived.top,
              scale: actived.scaleX
            }
            this.$refs.refImgList.setObject(actived.imgUid)
            this.$refs.refImgList.setPosi(paramPosi)
          }
        }
      })
      canvas.on('mouse:up', () => {
        // this.KnifePreview();
      })
      canvas.on('object:modified', e => {
        this.KnifePreview()
        if (e.target.isFont) {
          return
        } else {
          const widthHeight = this.countWidthHeight(e.target)
          const DPI = this.countDPI(widthHeight.width, 2) // 1还没经过变化 2经过变化
          const actived = canvas.getActiveObject()
          this.$refs.refImgList.setDpi(actived.imgUid, DPI.dpi, DPI.dpiClass)
          const paramPosi = {
            scale: e.target.scaleX
          }
          this.$refs.refImgList.setPosi(paramPosi)
        }
        if (this.hasMirrow) {
          _this.handleMirror()
        }
      })
      canvas.on('object:moving', e => {
        if (e.target.isFont) {
          return
        } else {
          const actived = canvas.getActiveObject()
          const paramPosi = {
            posiX: actived.left,
            posiY: actived.top
          }
          _this.$refs.refImgList.setPosi(paramPosi)
        }
      })
      canvas.on('object:moved', () => {
        // this.KnifePreview();
      })
      canvas.on('object:removed', e => {
        this.KnifePreview()
        this.$refs.refImgList.spliceEditArr(e)
        if (canvas.getObjects().length === 0) {
          this.isVariants = false
        }
        // 删除克隆
        const mirrorId = 'mirror_copy_' + e.target.imgUid
        const objects = _this.canvas[this.knifeIndex].canvas.getObjects()
        objects.forEach(item => {
          if (item.imgUid === mirrorId) {
            _this.canvas[this.knifeIndex].canvas.remove(item)
          }
        })
      })
      // 画布精度问题，保留的小数位数
      fabric.Object.NUM_FRACTION_DIGITS = 20
      // 更改角落图标
      fabric.Object.prototype.customiseCornerIcons(
        {
          settings: {
            borderColor: '#2260b5',
            cornerShape: 'circle',
            // cornerBackgroundColor: "red",
            cornerPadding: 30
          },
          tr: {
            icon: rotateIcon
          },
          br: {
            icon: zoomIcon
          },
          bl: {
            icon: delIcon
          }
        },
        function() {
          canvas.renderAll()
        }
      )
      fabric.Canvas.prototype.customiseControls(
        {
          settings: {
            cornerSize: 35,
            cornerShape: 'rect',
            cornerPadding: 0
          },
          bl: {
            // 左下角
            action: 'remove',
            cursor: 'pointer'
          },
          tr: {
            // 右上角
            action: 'rotate',
            cursor: 'pointer'
          },

          mr: {
            actions: function() {
              console.log('....')
            },
            cursor: 'pointer'
          }
        },
        function() {
          _this.canvas[_this.knifeIndex].canvas.renderAll()
        }
      )
    },
    // 镜像图层
    handleMirror() {
      const _this = this
      this.hasMirrow = true
      const activeObject = _this.canvas[this.knifeIndex].canvas.getActiveObject()
      if (activeObject) {
        const mirrorId = 'mirror_copy_' + activeObject.imgUid
        const objects = _this.canvas[this.knifeIndex].canvas.getObjects()
        objects.forEach(item => {
          if (item.imgUid === mirrorId) {
            _this.canvas[this.knifeIndex].canvas.remove(item)
          }
        })
        const clone = fabric.util.object.clone(activeObject)
        clone.imgUid = mirrorId
        const cloneCanvas = this.initMirrow(clone)
        _this.canvas[this.knifeIndex].canvas.add(cloneCanvas)
        cloneCanvas.set('selectable', false)
      }
    },
    // 删除图层
    handleRemove() {
      const _this = this
      const activeObject = _this.canvas[this.knifeIndex].canvas.getActiveObject()
      if (activeObject) {
        const mirrorId = 'mirror_copy_' + activeObject.imgUid
        const objects = _this.canvas[this.knifeIndex].canvas.getObjects()
        objects.forEach(item => {
          if (item.imgUid === mirrorId) {
            _this.canvas[this.knifeIndex].canvas.remove(item)
          }
        })
      }
    },
    isMirror(isMirror) {
      if (isMirror) {
        this.handleMirror()
      } else {
        this.handleRemove()
      }
    },
    // 初始化镜像操作
    initMirrow(clone) {
      const width = this.windowsWidth - clone.getScaledWidth()
      const toLeft = width - clone.left
      clone.left = toLeft
      const scaleX = clone.scaleX
      clone.set('scaleX', -scaleX).setCoords()
      const angle = clone.angle
      clone.angle = 360 - angle
      return clone
    },
    // 切换刀版
    choiceKnife(e) {
      this.currKnifeId = e.id
      this.patternInfos.forEach(item => {
        if (item.isDefault === true) {
          item.knifeInfos.forEach(item2 => {
            if (e.id === item2.id) {
              item2.checked = true
            } else {
              item2.checked = false
            }
          })
        }
      })
    },
    // 修改背景图片
    changeColor(color) {
      for (let i = 0; i < this.canvas.length; i++) {
        const canvas = this.canvas[i].canvas
        canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas))
      }
      this.isBackgroundColorDialog = false
    },
    // 计算dpi(传的是当前图的大小)
    countDPI(ImageWidth, type) {
      const _this = this
      const knifeIndex = this.knifeIndex
      const canvas = this.canvas[knifeIndex]['canvas']
      const actived = canvas.getActiveObject()
      const objectWH = this.countWidthHeight(actived)
      const param = {}
      // 图片宽度
      const goodMin = _this.currBlankInfo.knifePlateImageDpiGoodMin
      const goodMax = _this.currBlankInfo.knifePlateImageDpiGoodMax

      const normalMin = _this.currBlankInfo.knifePlateImageDpiNormalMin
      const normalMax = _this.currBlankInfo.knifePlateImageDpiNormalMax
      const chicun =
        _this.currBlankInfo.knifePlateImageTransverseInch * actived.scaleX
      let dpi = 0
      console.log('actived.propWidth....', actived.propWidth)
      if (type === 1) {
        dpi = objectWH.width / chicun
      } else if (type === 2) {
        dpi = (actived.propWidth * actived.propWidth) / objectWH.width / chicun
      }
      param.dpiValue = dpi
      if (dpi >= goodMin && dpi <= goodMax) {
        param.dpi = 'High resolution'
        param.dpiClass = 'goods' // goods normal bad
      } else if (dpi >= normalMin && dpi <= normalMax) {
        param.dpi = 'Medium resolution'
        param.dpiClass = 'normal' // goods normal bad
      } else {
        param.dpi = 'Low resolution'
        param.dpiClass = 'bad' // goods normal bad
      }
      console.log('dpi....' + dpi)
      return param
    },
    showEff() {
      if (this.dialogEff === false) {
        this.dialogEff = true
      } else {
        this.dialogEff = false
      }
    },
    // 返回
    returnPage() {
      this.$router.go(-1)
    },
    // 关闭下载PSD弹窗
    closePSDDialog() {
      this.isShowPSDDialog = false
    },
    openUpload() {
      this.isEditFont = false
      this.isShowAddImgDialog = true
    },
    closAddImgDialog() {
      this.isShowAddImgDialog = false
    },
    toDesigns() {
      this.$refs.SysImage.isSysImageDialog = true
    },
    updateEditArr(val) {
      this.editArr = val
    },
    addImg(id, item) {
      this.$refs.refImgList.add(id, item)
    },
    unshiftEditArr(param) {
      this.$refs.refImgList.unshiftEditArr(param)
    },
    addText() {
      this.$refs.refFontEdit.addText()
    },
    getEditArr() {
      return this.$refs.refImgList.editArr
    },
    setPosi(param) {
      this.$refs.refImgList.setPosi(param)
    },
    onChangePreview(val) {
      this.preViewImg = val
    }
  }
}
</script>
<style lang="scss" scoped>
.canvas-box {
  background-image: url("../../assets/img/bg.svg");
  background-repeat: repeat;
  background-size: 5% 5%;
}
.return-head {
  height: 60px;
  background: #f2f2f2;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
  .return {
    font-size: 20px;
    cursor: pointer;
    color: #262626;
    margin-right: 15px;
  }
  .the-prompt {
    position: relative;
    width: 100%;
    color: #262626;
    flex: 1;
    border-radius: 4px;
    font-size: 18px;
  }
  .return-btns {
    margin-left: auto;
  }
}
.canvas-containers {
  margin: 0 auto;
}
.submit-h {
  width: 100%;
  height: 50px;
}
.submits {
  text-align: center;
  height: 50px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  text-align: right;
  .submit {
    width: 420px;
    height: 44px;
    background-color: #2260b5;
    font-size: 16px;
    border-radius: 4px;
  }
}
.dialog-eff {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 100000;
  img {
    width: 100%;
    height: 100%;
  }
  i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
}
.custom {
  background-color: white;
  // position: absolute;
  // top: 0px;
  // left: 0;
  width: 100%;
  height: 100%;
  min-width: 1200px;
  // overflow-x: scroll;
  .await {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    z-index: 1000;
    display: flex;
    .aspin {
      position: absolute;
      top: 50%;
    }
    .await-left {
      width: 270px;
      background-color: rgba(0, 0, 0, 0);
    }
    .await-center {
      height: 100%;
      flex: 1;
      background: white;
      // margin-left: 171px;
      margin-top: 66px;
    }
    .await-right {
      width: 550px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .item-title {
    margin-bottom: 15px;
    font-size: 16px;
    color: #999999;
  }
  .item-wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: 20px;
    .ant-checkbox-wrapper {
      margin-left: 0;
      margin-bottom: 10px;
      margin-right: 8px;
    }
    .item-content {
      display: inline-block;
      padding: 2px 13px;
      border-radius: 10px;
      background-color: #ffffff;
      color: #333333;
      font-size: 12px;
      margin-right: 20px;
      border: 2px solid #110e0e;
      box-sizing: border-box;

      &.actived {
        border-color: #fe3a3a;
        color: #fe3a3a;
      }

      &.noactived {
        background-color: #f4f4f4;
        border-color: #f4f4f4;
      }
    }
  }

  .the-head {
    height: 35px;
    padding: 5px 9px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 400px;
    border-left: 1px solid #f2f2f2;
    cursor: pointer;
  }
  .content {
    display: flex;
    .canvas {
      flex: 1;
      position: relative;
      display: flex;
      .preview {
        flex: 1;
        left: 0;
        width: 100%;
        text-align: center;
        height: 60px;
        border-top: 1px solid #f2f2f2;
        button {
          background-color: #000;
          width: 315px;
          height: 40px;
          margin-top: 10px;
          border-radius: 5px;
          font-size: 18px;
        }
      }
    }
    .control {
      width: 80px;
      .control-li {
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        text-align: center;
        height: 64px;
        padding-top: 12px;
        cursor: pointer;
        &:active {
          background: #2260b5;
          color: white;
          border-radius: 4px;
        }
        img {
          width: 21px;
          height: 21px;
          display: block;
          margin: 0 auto;
        }
        span {
          font-size: 12px;
          display: inline-block;
          margin-top: 5px;
        }
      }
    }
    .edit {
      width: 420px;
      margin-right: 30px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      margin-left: 20px;
      position: relative;
      .edit-tab {
        display: flex;
        height: 56px;
        padding-top: 15px;
        .edit-tab-li {
          text-align: center;
          flex: 1;
          font-size: 16px;
          cursor: pointer;
          span {
            display: inline-block;
            position: relative;
          }
          span.actived {
            color: #2260b5;
            &:after {
              content: "";
              position: absolute;
              bottom: -8px;
              left: 50%;
              margin-left: -60px;
              width: 120px;
              height: 2px;
              border: 2px solid #2260b5;
            }
          }
        }
      }
      .edit-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 544px;
        &::-webkit-scrollbar {
          width: 9px;
          height: 9px;
        }

        /*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

        &::-webkit-scrollbar-track {
          width: 6px;
          background-color: white;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }

        /*滚动条的设置*/

        &::-webkit-scrollbar-thumb {
          background-color: #606d71;
          background-clip: padding-box;
          min-height: 28px;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
        /*滚动条移上去的背景*/

        &::-webkit-scrollbar-thumb:hover {
          background-color: #fff;
        }
        // &::-webkit-scrollbar-thumb {
        //   background-color: #606d71;
        //   background-clip: padding-box;
        //   min-height: 28px;
        //   -webkit-border-radius: 2em;
        //   -moz-border-radius: 2em;
        //   border-radius: 2em;
        // }
      }
      .first {
        .f-title {
          text-align: left;
          height: 70px;
          line-height: 70px;
          border-bottom: 1px solid #e7e7e7;
          font-size: 20px;
        }
        .f-add {
          height: 153px;
          margin: 15px auto;
          width: 371px;
          padding: 10px 0;
          border: 1px dashed #e7e7e7;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          p {
            margin: 5px 0;
            font-size: 16px;
          }
          span {
            font-size: 12px;
            color: rgba(56, 56, 56, 0.3);
            margin-top: 5px;
          }
          &:hover {
            background-color: #e1e1e1;
          }
        }
      }
    }
  }
}
</style>
