<template>
  <div v-if="isShowPSDDialog" class="downpsd">
    <div class="dialog-psd">
      <div class="icon-close-con" @click="closePSDDialog">
        <img src="../../../assets/img/page-closed.svg" class="icon-close">
      </div>
      <img src="../../../assets/img/icon-PSD.svg" alt="">
      <p>File Size: {{ psdFileSize }}</p>
      <a-button
        v-if="psdFileUrl"
        type="primary"
        size="large"
        @click="goDownPSD"
      >Download PSD Template</a-button>
    </div>
  </div>
</template>
<script>
import { change } from '@/utils/util.js'
export default {
  props: {
    isShowPSDDialog: {
      type: Boolean
    },
    psdFileUrl: {
      type: String
    },
    psdSize: {
      type: Number
    }
  },
  data() {
    return {
      psdFileSize: this.psdSize
    }
  },
  updated() {
    if (this.psdSize) {
      this.psdFileSize = change(this.psdSize)
    } else {
      this.psdFileSize = '0KB'
    }
  },
  methods: {
    closePSDDialog() {
      this.$emit('close')
    },
    // 去下载PSD
    goDownPSD() {
      window.location.href = this.psdFileUrl
    }
  }
}
</script>
<style scoped lang="scss">
.downpsd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  .dialog-psd {
    border-radius: 5px;
    border-top-right-radius: 0px;
    position: fixed;
    background: white;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 30px;
    width: 700px;
    height: 500px;
    text-align: center;
    .icon-close-con {
      position: absolute;
      top: 0px;
      right: -30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: white;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      .icon-close {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        margin-top: 7px;
      }
    }
    img {
      width: 90px;
      height: 75px;
      margin-top: 119px;
    }
    p {
      font-size: 14px;
      color: #808080;
      margin-top: 17px;
    }
    button {
      border-radius: 5px;
      margin-top: 59px;
    }
  }
}
</style>
