<template>
  <div class="setbackground">
    <div class="downpsd">
      <div class="dialog-psd">
        <div
          class="icon-close-con"
          @click="$parent.isBackgroundColorDialog = false"
        >
          <img src="../../../assets/img/page-closed.svg" class="icon-close">
        </div>
        <div class="dialog-title">Select Background Color</div>
        <div class="dialog-color">
          <ul>
            <li
              v-for="(item, index) in colorList"
              :key="index"
              :class="{ actived: item.color == color }"
              :style="{ 'background-color': item.color }"
              @click="choiceColor(item.color)"
            />
          </ul>
        </div>
        <div class="dialog-add">
          <span>Add a valid HEX color：</span>
          <a-input-search
            v-model="inputColor"
            style="width: 334px"
            placeholder="input search text"
            enter-button="Add"
            size="large"
            @search="onAdd"
          />
        </div>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" class="btns">
          <a-button
            size="large"
            @click="$parent.isBackgroundColorDialog = false"
          >
            Cancel
          </a-button>
          <a-button
            style="margin-left: 44px;"
            type="primary"
            size="large"
            @click="confirm"
          >
            Confirm
          </a-button>
        </a-form-model-item>
      </div>
    </div>
  </div>
</template>
<script>
import { colorList } from '../../../api/product'
export default {
  data() {
    return {
      color: '',
      inputColor: '',
      colorList: []
    }
  },
  async mounted() {
    this.getColorList()
  },
  methods: {
    async getColorList() {
      const { data } = await colorList()
      this.colorList = data.colors
    },
    choiceColor(item) {
      this.color = item
    },
    confirm() {
      this.$emit('changeBgColor', this.color)
      this.$emit('real')
    },
    onAdd() {
      this.color = ''
      this.$emit('changeBgColor', this.inputColor)
    }
  }
}
</script>
<style scoped lang="scss">
.downpsd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  .dialog-psd {
    border-radius: 5px;
    border-top-right-radius: 0px;
    position: fixed;
    background: white;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 23px;
    width: 740px;
    text-align: center;
    .icon-close-con {
      position: absolute;
      top: 0px;
      right: -30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: white;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      .icon-close {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        margin-top: 7px;
      }
    }
    .dialog-title {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
    }
    .dialog-color {
      margin-top: 38px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50px;
          height: 50px;
          margin-left: 17px;
          margin-bottom: 15px;
          background-color: #e95e70;
          border-radius: 100px;
          &.actived {
            background-image: url("../../../assets/img/gou.svg") !important;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 50% 50%;
          }
        }
      }
    }
    .dialog-add {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        white-space: nowrap;
        margin-right: 15px;
        margin-left: 17px;
      }
    }
    .btns {
      margin-top: 51px;
      button {
        width: 138px;
      }
    }
  }
}
</style>
