<template>
  <!-- 图片预览 -->
  <div class="dialog">
    <div class="d-content tps">
      <div class="icon-close-con" @click="handClose">
        <img src="../../../assets/img/page-closed.svg" class="icon-close">
      </div>
      <div v-for="(item,index) in customPageNotices" :key="index">
        <p v-html="item.content" />
      </div>
    </div>
  </div>
</template>
<script>
import { productDetail } from '@/api/product'
export default {
  props: {
    productId: [String, Number]
  },
  data() {
    return {
      customPageNotices: []
    }
  },
  watch: {
    productId(newValue, oldValue) {
      this.productId2 = newValue
      this.getData()
    }
  },
  created() {
    if (this.productId) {
      this.productId2 = this.productId
    }
  },
  methods: {
    // 获取产品详情
    async getData() {
      const { data, code } = await productDetail({
        id: this.productId,
        customPageNotice: true
      })
      if (code === 200) {
        console.log('aaa...', data)
        if (data.customPageNotices && data.customPageNotices.length > 0) {
          this.customPageNotices = data.customPageNotices
          this.$emit('open')
        } else {
          this.handClose()
        }
      }
    },
    handClose() {
      this.$emit('close')
    }
  }
}
</script>
<style >
.tps img {
  max-width: 80%;
}
</style>
<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  .d-content {
    position: fixed;
    left: 27.5%;
    width: 45%;
    height: 480px;
    overflow: hidden;
    overflow-y: auto;
    background: white;
    top: 50%;
    margin-top: -240px;
    padding: 30px 50px;
    .icon-close-con {
      position: absolute;
      top: 15px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background: white;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      .icon-close {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        margin-top: 7px;
      }
    }
  }
}
</style>
