<template>
  <div>
    <div
      v-for="(item, index1) in specifications"
      :key="index1"
      class="specification-item"
    >
      <div class="item-title">{{ item.name }}</div>
      <div class="item-wrapper">

        <!-- <label
          v-for="(item_value, index2) in item.items"
          :key="index2"
        ><input
           type="checkbox"
           :checked="item_value.ischeck"
           @change.stop="skuClick(item_value, index1, $event, index2)"
         >
          <span
            v-if="item_value.option.color"
            :style="'background-color:' + item_value.option.color"
          />{{ item_value.name }}
        </label> -->
        <div
          v-for="(item_value, index2) in item.items"
          :key="index2"
          class="radioItem "
          :class="item_value.ischeck ? 'actived' : ''"
          @click="skuClick(item_value, index1, $event, index2)"
        >
          <div v-if="item_value.option.color">
            <span :style="'background-color:' + item_value.option.color" />
            {{ item_value.name }}
          </div>
          <div v-else-if="item_value.option.fileUrl">
            <img :src="item_value.option.fileUrl" alt="">
            {{ item_value.name }}
          </div>
          <div v-else>
            {{ item_value.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    specifications: {
      type: Array
    },
    selectSkuIdArr: {
      type: Array
    }
  },
  data() {
    return {
      selectSkuIdList: this.selectSkuIdArr
    }
  },
  methods: {
    // 选择sku
    skuClick(value, index1, event, index2) {
      if (this.specifications[index1]['items'][index2]['ischeck']) {
        this.specifications[index1]['items'][index2]['ischeck'] = false
        this.$forceUpdate()
      } else {
        this.specifications[index1]['items'][index2]['ischeck'] = true
        this.$forceUpdate()
      }
    },
    // 获取选中的sku
    getSelectSkuIdList() {
      const len = this.specifications.length
      this.selectSkuIdList.length = 0
      this.skutipArr = ''
      const param = {
        sku0: 0,
        sku1: 0,
        sku2: 0
      }
      for (let i = 0; i < this.specifications.length; i++) {
        for (let j = 0; j < this.specifications[i]['items'].length; j++) {
          if (this.specifications[i]['items'][j]['ischeck']) {
            param['sku' + i] = param['sku' + i] + 1
            this.selectSkuIdList.push(
              ...this.specifications[i]['items'][j]['ids']
            )
          }
        }
      }
      const a = {}
      var repArr = []
      for (let t = 0; t <= this.selectSkuIdList.length - 1; t++) {
        const v = this.selectSkuIdList[t]

        if (a[v]) {
          a[v]++
        } else {
          a[v] = 1
        }
      }
      for (const key in a) {
        if (a[key] === len) {
          repArr.push(parseInt(key))
        }
      }
      if (this.specifications.length === 1) {
        if (param.sku0 === 0) {
          this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
        }
      } else if (this.specifications.length > 1) {
        this.selectSkuIdList = repArr
        if (this.specifications.length === 2) {
          if (param.sku0 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
          }
          if (param.sku1 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[1].name + ' '
          }
          // 有两种属性但是只选了一种
        } else if (this.specifications.length === 3) {
          if (param.sku0 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
          }
          if (param.sku1 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[1].name + ' '
          }
          if (param.sku2 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[2].name + ' '
          }
        }
      }
    },
    handIsShwoSku() {
      this.isSku = !this.isSku
    }
  }
}
</script>
<style lang="scss" scoped>
.radioItem{
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px !important;
  border: 1px solid #d9d9d9;
  padding: 5px 15px;
  cursor: pointer;
  img{
    width: 30px;
    height: 30px;
  }
  span{
     display: inline-block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: red;
      margin-right: 8px;
      border: 1px solid #e6e6e6;
  }
  &.actived{
        color: #29b8ff;
    border-color: #29b8ff;

  }
}
.specification-item {
  margin-left: 20px;
  .item-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 21px;
  }
  .item-wrapper {
    display: flex;
    flex-wrap: wrap;
    label {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 36px;
      margin-bottom: 16px;
    }
    span {
      display: inline-block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: red;
      margin-right: 8px;
      border: 1px solid #e6e6e6;
    }
    input[type="checkbox"]:checked {
      width: 26px;
      height: 26px;
      background: url("../../../assets/img/icon-selected02.svg") no-repeat;
      background-size: 100%;
    }
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      margin-right: 8px;
      border: 1px solid #e6e6e6;
      border-radius: 100px;
      width: 26px;
      height: 26px;
    }
  }
}
</style>
