<template>
  <div class="operation">
    <div class="operation-left-box-con">
      <a-tooltip placement="topRight" overlay-class-name="tooltip">
        <template slot="title">
          <span>Horizontal Flip</span>
        </template>
        <!-- <a-button>TR</a-button> -->
        <div class="operation-li" @click="align('horizontally')">
          <img src="../../../assets/img/flip-horizontally.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight" overlay-class-name="tooltip">
        <template slot="title">
          <span>Vertical Flip</span>
        </template>
        <div class="operation-li" @click="align('vertically')">
          <img src="../../../assets/img/flip-vertically.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Fill</span>
        </template>
        <div class="operation-li" @click="align('fill')">
          <img src="../../../assets/img/fill.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Vertical Center</span>
        </template>
        <div class="operation-li" @click="align('levelCentered')">
          <img src="../../../assets/img/c_vertically-centered.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Horizontal Center</span>
        </template>
        <div class="operation-li" @click="align('verticallyCentered')">
          <img src="../../../assets/img/c_center-horizontally.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Left</span>
        </template>
        <div class="operation-li" @click="align('left')">
          <img src="../../../assets/img/c_left.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Right</span>
        </template>
        <div class="operation-li" @click="align('right')">
          <img src="../../../assets/img/c_right.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Top</span>
        </template>
        <div class="operation-li" @click="align('top')">
          <img src="../../../assets/img/c_top.svg" mode>
        </div>
      </a-tooltip>
      <a-tooltip placement="topRight">
        <template slot="title">
          <span>Buttom</span>
        </template>
        <div class="operation-li" @click="align('bottom')">
          <img src="../../../assets/img/c_botton.svg" mode>
        </div>
      </a-tooltip>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    knifeIndex: {
      type: Number
    },
    canvas: {
      type: Array
    },
    scaling: {
      type: Number
    },
    windowsWidth: {
      type: Number
    }
  },
  data() {
    return {
      isMirror: false
    }
  },
  methods: {
    onChange() {
      this.$emit('isMirror', this.isMirror)
    },
    align(val) {
      const knifeIndex = this.knifeIndex
      const canvas = this.canvas[knifeIndex].canvas
      const currActiveObject = canvas.getActiveObject()
      const widthHeight = this.countWidthHeight(currActiveObject)
      const currActiveObjectWidth = widthHeight.width
      const currActiveObjectHeight = widthHeight.height
      console.log('currActiveObject.getBoundingRect().....')
      console.log(currActiveObject.getBoundingRect())
      if (val === 'horizontally') {
        // 水平翻转
        canvas
          .getActiveObject()
          .set('scaleX', -currActiveObject.scaleX)
          .setCoords()
      } else if (val === 'vertically') {
        // 垂直翻转
        canvas
          .getActiveObject()
          .set('scaleY', -currActiveObject.scaleY)
          .setCoords()
      } else if (val === 'fill') {
        // 拉伸
        if (currActiveObjectWidth >= currActiveObjectHeight) {
          currActiveObject.scaleToWidth(this.windowsWidth)
          currActiveObject.centerV().setCoords()
          currActiveObject.centerH().setCoords()
        } else if (currActiveObjectHeight > currActiveObjectWidth) {
          currActiveObject.centerV().setCoords()
          currActiveObject.centerH().setCoords()
          currActiveObject.scaleToHeight(this.windowsWidth)
        }
      } else if (val === 'levelCentered') {
        // 水平居中
        currActiveObject.centerV().setCoords()
      } else if (val === 'verticallyCentered') {
        // 垂直居中
        currActiveObject.centerH().setCoords()
      } else if (val === 'top') {
        currActiveObject.set('top', parseInt(0, 10)).setCoords()
        const bound = currActiveObject.getBoundingRect()
        currActiveObject.set('top', parseInt(-bound.top, 10)).setCoords()
      } else if (val === 'right') {
        const right = this.windowsWidth - currActiveObjectWidth
        currActiveObject.set('left', parseInt(right, 10)).setCoords()
      } else if (val === 'bottom') {
        const bound2 = currActiveObject.getBoundingRect()
        const bottom = this.windowsWidth - bound2.height
        currActiveObject.set('top', parseInt(bottom, 10)).setCoords()
      } else if (val === 'left') {
        currActiveObject.set('left', parseInt(0, 10)).setCoords()
      }
      // t.center();    //全部居中
      // t.centerH();   //水平居中
      // t.centerV();   //垂直居中
      canvas.renderAll()
      this.$emit('real')
    },
    // 计算宽高
    countWidthHeight(e) {
      const arrY = []
      const arrX = []
      arrY.push(e.aCoords.tl.y)
      arrY.push(e.aCoords.tr.y)
      arrY.push(e.aCoords.bl.y)
      arrY.push(e.aCoords.br.y)

      arrX.push(e.aCoords.tl.x)
      arrX.push(e.aCoords.tr.x)
      arrX.push(e.aCoords.bl.x)
      arrX.push(e.aCoords.br.x)

      var maxY = Math.max.apply(null, arrY)
      var minY = Math.min.apply(null, arrY)

      var maxX = Math.max.apply(null, arrX)
      var minX = Math.min.apply(null, arrX)
      this.sizeHeight = maxY - minY
      const param = {}
      param.height = maxY - minY
      param.width = maxX - minX
      return param
    }
  }
}
</script>
<style scoped lang="scss">
.operation {
  height: 60px;
  display: flex;
  margin-top: 10px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  padding-top: 35px;
  margin-left: -244px;
  .operation-left-box-con {
    width: 488px;
    margin: 0 auto;
    height: 60px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    border-radius: 4px;
    .operation-li {
      width: 34px;
      height: 34px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-left: 18px;
      img {
        width: 24px;
        height: 24px;
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }
}
</style>
