<template>
  <div v-if="isSysImageDialog" class="sysImage">
    <div ref="imgul" class="dialog-image">
      <div class="icon-close-con" @click="isSysImageDialog = false">
        <img src="../../../assets/img/page-closed.svg" class="icon-close">
      </div>
      <a-row type="flex" class="m-t-26">
        <a-col :span="8">
          <a-input-search
            v-model="fromPage.keyword"
            size="large"
            placeholder="input search text"
            enter-button
            @search="onSearch"
          />
          <a-row class="m-t-30">
            <a-col>
              <a-tag
                v-for="(item, index) in tagList"
                :key="index"
                class="tag"
                :class="{ actived: index === tagIndex }"
                @click="changeTag(index, item)"
              >{{ item }}</a-tag>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="15" :offset="1">
          <ul
            v-if="imageList.length > 0"
            ref="scroll"
            class="img-list"
            style="overflow-y: scroll;"
            :style="{ height: screenHeight - 50 + 'px' }"
            @scroll="handleScroll"
          >
            <li v-for="(item, index) in imageList" :key="index">
              <img :src="item" @click="addImage(item)">
            </li>
          </ul>
          <div v-else class="none">
            No search results found.
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { imagePage, tagList } from '../../../api/gallery'
import uuid from 'licia/uuid'
export default {
  data() {
    return {
      screenHeight: 0,
      isSysImageDialog: false,
      isEnd: false, // 分页是否加载完成
      fromPage: {
        current: 0,
        size: 30,
        tag: '',
        keyword: ''
      },
      imageList: [],
      tagList: [],
      tagIndex: ''
    }
  },
  mounted() {
    const _this = this
    _this.screenHeight =
      document.body.offsetHeight - document.body.offsetHeight * 0.2
    this.getTagList()
    this.getImagePage()
  },
  methods: {
    handleScroll() {
      this.isToBottom()
        .then(() => this.getImagePage())
        .catch(() => {})
    },
    isToBottom() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const { scrollTop, scrollHeight, clientHeight } = this.$refs.scroll
          if (parseInt(scrollHeight - scrollTop) === clientHeight) {
            this.page.current = this.page.current + 1
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取图片分页
    async getImagePage() {
      const { data } = await imagePage(this.fromPage)
      this.imageList.push(...data.imageUrls)
      if (data.imageUrls.length === 0) {
        this.isEnd = true
      }
      console.log(data)
    },
    // 获取标签列表
    async getTagList() {
      const { data } = await tagList()
      this.tagList = data.tags
      console.log(data)
    },
    addImage(item) {
      const uid = uuid()
      const param = {
        fileUrl: item,
        fileName: item.substring(item.length - 10, item.length - 4),
        id: uid
      }
      this.isSysImageDialog = false
      this.$emit('add', uid, param)
    },
    // 选择tag
    changeTag(index, item) {
      this.tagIndex = index
      this.fromPage.tag = item
      this.fromPage.current = 1
      this.imageList = []
      this.getImagePage()
    },
    onSearch() {
      this.tagIndex = ''
      this.fromPage.tag = ''
      this.fromPage.current = 1
      this.imageList = []
      this.getImagePage()
    }
  }
}
</script>
<style scoped lang="scss">
.sysImage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  .dialog-image {
    border-radius: 5px;
    border-top-right-radius: 0px;
    position: fixed;
    background: white;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 0 23px;
    width: 66%;
    height: 80%;
    .none {
      position: absolute;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
    .icon-close-con {
      position: absolute;
      top: 0px;
      right: -30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: white;
      .icon-close {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        margin-top: 7px;
        border-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    .tag {
      margin-bottom: 10px;
      background: white;
      padding: 2px 8px;
      &.actived {
        border: 1px solid #106ebe;
      }
    }
    .img-list {
      display: grid;
      grid-template-columns: repeat(5, 20%); // 设置列
      li {
        width: 140px;
        height: 140px;
        margin-bottom: 15px;
        text-align: center;
        cursor: pointer;
        :hover {
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        }
        img {
          margin-top: 10px;
          width: 120px;
          height: 120px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
</style>
