<template>
  <div>
    <div class="knife" :style="'height:' + (windowHeight - 35 - 31) + 'px'">
      <!-- 实时效果图 -->
      <div class="realSwiper">
        <div v-show="realTimePreviewLoading" class="dis-progress">
          <div class="progress">
            <span class="progress-text">GENERATING...</span>
            <span>{{ progress }}%</span>
          </div>
          <a-progress
            :percent="progress"
            :show-info="false"
            stroke-color="#2260b5"
          />
        </div>
        <template v-if="showImageList">
          <a-icon
            v-show="showImageList.length > 1"
            type="left"
            style="font-size: 20px"
            @click="handlePrev"
          />
          <div class="realSwiper-img">
            <a-carousel ref="carousel" arrows>
              <div v-for="(item, index) in showImageList" :key="index">
                <img :src="item">
              </div>
            </a-carousel>
          </div>
          <a-icon
            v-show="showImageList.length > 1"
            type="right"
            style="font-size: 20px"
            @click="handleNext"
          />
        </template>
        <div class="prive" @click="genterPreview">Preview Mockups</div>
      </div>

      <!-- 刀版列表 -->
      <div v-if="currBlankInfo" class="dis-flex">
        <div
          v-for="(item, index) in currBlankInfo.knifeInfos"
          :key="index"
          class="knife-list"
        >
          <div
            class="knife-li"
            :class="{ actived: item.checked }"
            @click="choiceKnife(item.id, item.effectTipImageUrl)"
          >
            <img :src="item.knifePlateImageUrl">
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 效果图预览 -->
    <div v-show="isSwiperPopup" class="effect-preview">
      <div class="content">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) in showImageList"
            :key="index"
            class="swiper-slide"
          ><img
            :src="item"
          ></swiper-slide>
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
        <div class="closeSwiper" @click="isSwiperPopup = false">
          <img src="../../../assets/img/cross.png">
        </div>
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/swiper-bundle.min.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
// import images from "@/components/images";
// Import Swiper styles
SwiperCore.use([Navigation, Autoplay, Pagination])
export default {
  components: {
    Swiper,
    SwiperSlide
    // images
  },
  props: {
    showImageList: {
      type: Array
    },
    currBlankInfo: {
      type: Object
    },
    knifePlateImageUrls: {
      type: String
    },
    windowHeight: {
      type: [String, Number]
    },
    realTimeImage: {
      type: Array
    },
    realTimePreviewLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progress: 0,
      isShowPreviewPopup: false,
      isSwiperPopup: false,
      swiperOptions: {
        slidesPerView: 1,
        grabCursor: true,
        centeredSlides: true,
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false
        // },
        loop: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      knifeIndex: 0,
      knifePlateImageUrl: ''
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
    // currentImage() {
    //   if (this.realTimeImage.length) {
    //     return this.realTimeImage;
    //   }
    //   return `${this.showImageList[0]}?x-oss-process=image/resize,w_240,h_240/format,jpg/quality,q_80`;
    // }
  },
  watch: {
    knifePlateImageUrls() {
      this.knifePlateImageUrl = this.knifePlateImageUrls
    },
    realTimePreviewLoading(val) {
      const _this = this
      let set = null
      let count = 0
      this.progress = 0
      if (val === true) {
        set = setInterval(() => {
          count = count + 1
          if (count === 8) {
            clearInterval(set)
          }
          _this.progress = _this.progress + 10
        }, 1000)
      } else {
        clearInterval(set)
        this.progress = 100
      }
    }
  },
  mounted() {
    console.log('Current Swiper instance object', this.swiper)
    this.swiper.slideTo(1, 1000, false)
  },
  methods: {
    handlePrev() {
      this.$refs.carousel.prev()
    },
    handleNext() {
      this.$refs.carousel.next()
    },
    genterPreview() {
      this.$parent.preview_fun()
    },
    openSwiperPopup() {
      this.isSwiperPopup = !this.isSwiperPopup
    },
    choiceKnife(id, url) {
      // console.log("index....." + index);
      // this.knifeIndex = index;
      // this.knifePlateImageUrl = url;
      const param = {}
      param.id = id
      param.url = url
      this.$emit('choice', param)
    }
  }
}
</script>
<style lang="scss">
.progress {
  display: flex;
  .progress-text {
    display: inline-block;
    padding: 5px 10px;
    color: white;
    border-radius: 50px;
    background: #2260b5;
  }
  span:nth-child(2) {
    margin-left: auto;
    color: #2260b5;
  }
}
.swiper-prev:after {
  font-size: 16px;
}
.swiper-next:after {
  font-size: 16px;
}
.realSwiper {
  width: 240px;
  height: 280px;
  position: relative;
  .dis-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    top: 10px;
    z-index: 1;
  }
  .anticon-left {
    position: absolute;
    top: 50%;
    left: 0 !important;
    transform: translate(0%, -50%);
    z-index: 1;
  }
  .anticon-right {
    position: absolute;
    top: 50%;
    right: 0 !important;
    transform: translate(0%, -50%);
    z-index: 1;
  }
  .realSwiper-img {
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
    h3 {
      height: 50px;
    }
  }
}
</style>
<style scoped lang="scss">
.effect-preview {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 100;
  .content {
    width: 500px;
    height: 500px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .closeSwiper {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 11px;
      right: 6px;
      z-index: 10;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .swiper-slide img {
      width: 500px;
      height: 500px;
    }
  }
}

.swipers {
  text-align: center;
  img {
    width: 104px;
    height: 104px;
  }
}

.knife {
  height: 100vh;
  overflow: hidden;
  width: 240px;
  margin-left: 30px;
  overflow-y: scroll;
  .realSwiper {
    position: relative;
    border: 2px solid #e6e6e6;
    overflow: hidden;
    img {
      width: 240px;
      height: 240px;
    }
    .prive {
      width: 240px;
      height: 40px;
      background: #2260b5;
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 40px;
      text-align: center;
      color: white;
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .knife-btn {
    text-align: center;
    height: 20px;
  }
  .dis-flex {
    justify-content: space-between;
    flex-wrap: wrap;
    &:after {
      content: "";
      display: block;
      width: 70px;
      height: 70px;
      visibility: hidden;
    }
    .knife-list {
      width: 70px;
      margin-top: 16px;
      border-radius: 4px;
      text-align: center;
      span {
        margin-top: 11px;
        display: inline-block;
        color: #808080;
      }
      .knife-li {
        border: 1px solid #f2f2f2;
        &.actived {
          // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          border: 1px solid #2260b5;
        }
        img {
          width: 64px;
          height: 64px;
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }
}
</style>
