<template>
  <div>
    <div v-show="designVariantsActived === 'design'" class="img-edit">
      <div class="th-contents">
        <!-- 当前编辑状态 -->
        <div v-for="(item, index) in editArr" :key="index">
          <div v-for="(items, index2) in item.arr" :key="items.imgUid">
            <div
              v-if="item.knifeIndex === knifeIndex"
              class="th-con"
              @click="choiceObject(items, index, index2)"
            >
              <div class="th-list th-active-bg">
                <img
                  :id="items.id"
                  :src="
                    items.isFont
                      ? require('../../../assets/img/duohang.svg')
                      : items.fileUrl
                  "
                  class="icon-img"
                  crossorigin="anonymous"
                >
                <div class="th-msg">
                  <p class="th-name ellipsis">
                    {{ items.isFont ? items.text : items.fileName }}
                  </p>
                  <p
                    :class="
                      'th-size th-' + (items.isFont ? 'blue' : items.dpiClass)
                    "
                  >
                    {{ items.isFont ? "Add Lib" : items.dpi }}
                  </p>
                </div>
                <img
                  src="../../../assets/img/move-up.svg"
                  class="icon-del"
                  @click.stop="
                    setForward('bringForward', items.imgUid, index, index2)
                  "
                >
                <img
                  src="../../../assets/img/move-down.svg"
                  class="icon-del"
                  @click.stop="
                    setForward('sendToBack', items.imgUid, index, index2)
                  "
                >
                <img
                  src="../../../assets/img/delete.svg"
                  class="icon-del"
                  @click.stop="removeCanvasObject(items.imgUid, index, index2)"
                >
                <img
                  :src="
                    items.isEyes
                      ? require('../../../assets/img/visible.svg')
                      : require('../../../assets/img/invisible.svg')
                  "
                  class="icon-del"
                  @click.stop="handVisible(items.isEyes, items.imgUid)"
                >
              </div>
              <div
                v-if="
                  items.isShow && item.knifeIndex === knifeIndex && !items.isFont
                "
                class="th-change"
              >
                <div class="th-change-head">
                  <span>Scale:</span>
                  <span>Rotate:</span>
                </div>
                <div class="th-change-con">
                  <div class="th-change-flex">
                    <div class="th-change-box">
                      <img
                        src="../../../assets/img/icon-sub.svg"
                        alt
                        @click="scaleTranfro('sub', items.scale)"
                      >
                    </div>
                    <div class="th-change-box">
                      <img
                        src="../../../assets/img/icon-plus.svg"
                        alt
                        @click="scaleTranfro('add', items.scale)"
                      >
                    </div>
                    <a-input-number
                      v-model="items.scale"
                      :step="0.01"
                      :precision="2"
                      @change="changeScale"
                    />
                  </div>
                  <div class="th-change-flex">
                    <div
                      class="th-change-box"
                      @click="rotateTransfro('sub', items.rotate)"
                    >
                      <img
                        src="../../../assets/img/anticlockwise-rotation.svg"
                        alt
                      >
                    </div>
                    <div
                      class="th-change-box"
                      @click="rotateTransfro('add', items.rotate)"
                    >
                      <img
                        src="../../../assets/img/clockwise-rotation.svg"
                        alt
                      >
                    </div>
                    <a-input-number
                      v-model="items.rotate"
                      :step="1"
                      :precision="2"
                      @change="changeRotate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加图片弹窗 -->
    <div v-if="isShowAddImgDialog" class="foru-modal">
      <div class="img-box">
        <div class="icon-close-con" @click="closAddDialog">
          <img src="../../../assets/img/page-closed.svg" class="icon-close">
        </div>
        <div class="imgbox">
          <div class="imgBox-con">
            <a-upload
              class="upload-demo"
              :show-upload-list="false"
              :custom-request="fnUploadRequest"
              accept=".jpg, .jpeg, .png"
              action
              multiple
            >
              <a-button
                size="large"
                class="bg000"
                type="primary"
              >Upload An Image</a-button>
            </a-upload>
          </div>
          <a-upload-dragger
            class="img-add-normal"
            :show-upload-list="false"
            :custom-request="fnUploadRequest"
            accept=".jpg, .jpeg, .png"
            :style="{ height: screenHeight - 300 + 'px' }"
            action
          >
            <div class="img-add-normal-box">
              <img src="../../../assets/img/unload-image.svg">
              <p style="margin-top:28px">Upload or drag your image here.</p>
              <p>PNG,JPG</p>
              <p>Maximum file size 80 MB</p>
              <!-- <p>PNG,JPG,SVG,AI,CDR</p> -->
            </div>
          </a-upload-dragger>
          <div class="img-link">
            I confirm this content does not infringe any laws or third-party
            rights like copyright, trademark, or personality rights, and have
            reviewed our
            <a
              href="https://www.jetprintapp.com/faq/"
              target="_blank"
            >legal guidelines</a>
            for designs.
          </div>
        </div>
        <div class="img-li">
          <a-spin :spinning="imgLoading">
            <ul
              ref="scroll"
              :style="{ height: screenHeight - 50 + 'px' }"
              @scroll="handleScroll"
            >
              <li
                v-for="(item, index) in imgList"
                :key="index"
                @click.stop="add(item.id, item)"
              >
                <images
                  :id="item.id"
                  :url="item.smallImageUrl"
                  width="184"
                  height="184"
                />
                <p class="img-name a-onLine">{{ item.fileName }}</p>
                <p class="img-size">
                  {{ item.imageWidth }}*{{ item.imageHeight }} px
                </p>
              </li>
            </ul>
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fabric } from 'fabric'
import images from '@/components/images'
import OSS from 'ali-oss'
import { filePage, delFile } from '@/api/file'
import { submitOssFile } from '@/api/file'
import { tempArray, getFileSize } from '@/utils/util.js'
import uuid from 'licia/uuid'
export default {
  components: {
    images
  },
  props: {
    designVariantsActived: {
      type: String
    },
    // 是否显示添加图片弹窗
    isShowAddImgDialog: {
      type: Boolean
    },
    submitAwait: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    ossData: {
      type: Object
    },
    knifeIndex: {
      type: Number
    },
    isVariants: {
      type: Boolean
    },
    specifications: {
      type: Array
    },
    canvas: {
      type: Array
    },
    canvasHeight: {
      type: Number
    },
    scaling: {
      type: Number
    },
    knifeLists: {
      type: Array
    },
    windowsWidth: {
      type: Number
    }
  },
  data() {
    return {
      screenHeight: 0,
      imgLodingFinsh: [], // 图片加载完成
      imgLoading: false,
      imgList: [], // 图片列表
      page: {
        current: 1,
        size: 15,
        fileType: 1
      },
      fileName: '', // 文件名
      isShowVariants: this.isVariants,
      sizeWidth: 0,
      sizeHeight: 0,
      posiX: 0, // Positioning(X)
      posiY: 0, // Positioning(X)
      scale: 0, // Positioning(X)
      editArr: []
    }
  },
  mounted() {
    this.filePage_fun()
    this.screenHeight = document.body.offsetHeight
  },
  methods: {
    // //数组对象交换（吧数组里的某个对象和另一个对象交换）
    // swapArray(arr, index1, index2) {
    //   arr[index1] = arr.splice(index2, 1, arr[index1])[0];
    //   console.log(arr);
    // },
    handleScroll() {
      this.isToBottom()
        .then(() => this.filePage_fun())
        .catch(() => {})
    },
    isToBottom() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const { scrollTop, scrollHeight, clientHeight } = this.$refs.scroll
          if (parseInt(scrollHeight - scrollTop) === clientHeight) {
            this.page.current = this.page.current + 1
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 更改编辑图层缩放比例
    changeScale(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      actived.scale(currentValue)
      canvas.renderAll()
      this.$emit('real')
    },
    // 更改编辑图层旋转角度
    changeRotate(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      actived.rotate(currentValue).setCoords()
      canvas.renderAll()
      this.$emit('real')
    },
    scaleTranfro(type, val) {
      const knifeIndex = this.knifeIndex
      const canvas = this.canvas[knifeIndex].canvas
      const actived = canvas.getActiveObject()
      if (type === 'sub' && val > 0.01) {
        val = val - 0.01
        actived.scale(val)
      } else {
        val = val + 0.01
        actived.scale(val)
      }
      const param = {}
      param.scale = val
      this.setPosi(param)
      this.$emit('real')
      const widthHeight = this.$parent.countWidthHeight(actived)
      const DPI = this.$parent.countDPI(widthHeight.width, 2) // 1还没经过变化 2经过变化
      this.setDpi(actived.imgUid, DPI.dpi, DPI.dpiClass)
    },
    rotateTransfro(type, val) {
      const knifeIndex = this.knifeIndex
      const canvas = this.canvas[knifeIndex].canvas
      const actived = canvas.getActiveObject()
      if (type === 'sub') {
        if (val <= 1) {
          val = 360 - val
        } else {
          val = val - 1
        }
        actived.rotate(val)
      } else {
        val = val + 1
        actived.rotate(val)
      }
      const param = {}
      param.rotate = val
      this.setPosi(param)
      this.$emit('real')
    },
    addFont() {
      this.$emit('addfont')
    },
    setPosi(param) {
      const editArr = this.editArr
      const knifeIndex = this.knifeIndex
      const canvas = this.canvas[knifeIndex].canvas
      const actived = canvas.getActiveObject()
      if (param.sizeWidth !== undefined) {
        for (let i = 0; i < editArr[knifeIndex].arr.length; i++) {
          if (editArr[knifeIndex].arr[i].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[i].sizeWidth = param.sizeWidth
          }
        }
      }
      if (param.sizeHeight !== undefined) {
        for (let h = 0; h < editArr[knifeIndex].arr.length; h++) {
          if (editArr[knifeIndex].arr[h].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[h].sizeHeight = param.sizeHeight
          }
        }
      }
      if (param.posiX !== undefined) {
        for (let x = 0; x < editArr[knifeIndex].arr.length; x++) {
          if (editArr[knifeIndex].arr[x].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[x].posiX = param.posiX
          }
        }
      }
      if (param.posiY !== undefined) {
        this.posiY = param.posiY
        for (let j = 0; j < editArr[knifeIndex].arr.length; j++) {
          if (editArr[knifeIndex].arr[j].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[j].posiY = param.posiY
          }
        }
      }
      if (param.scale !== undefined) {
        for (let s = 0; s < editArr[knifeIndex].arr.length; s++) {
          if (editArr[knifeIndex].arr[s].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[s].scale = param.scale
          }
        }
      }
      if (param.rotate !== undefined) {
        for (let r = 0; r < editArr[knifeIndex].arr.length; r++) {
          if (editArr[knifeIndex].arr[r].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[r].rotate = param.rotate
          }
        }
      }
      if (param.isEyes !== undefined) {
        for (let e = 0; e < editArr[knifeIndex].arr.length; e++) {
          if (editArr[knifeIndex].arr[e].imgUid === actived.imgUid) {
            this.editArr[knifeIndex].arr[e].isEyes = param.isEyes
          }
        }
      }
      if (param.fontSize !== undefined) {
        editArr[knifeIndex].arr.forEach(item => {
          if (item.imgUid === actived.imgUid) {
            item.fontSize = param.fontSize
          }
        })
      }
      if (param.text !== undefined) {
        editArr[knifeIndex].arr.forEach(item => {
          if (item.imgUid === actived.imgUid) {
            item.text = param.text
          }
        })
      }
      if (param.color !== undefined) {
        editArr[knifeIndex].arr.forEach(item => {
          if (item.imgUid === actived.imgUid) {
            item.color = param.color
          }
        })
      }
      if (param.fontFamily !== undefined) {
        editArr[knifeIndex].arr.forEach(item => {
          if (item.imgUid === actived.imgUid) {
            item.fontFamily = param.fontFamily
          }
        })
      }
      if (param.arc !== undefined) {
        editArr[knifeIndex].arr.forEach(item => {
          if (item.imgUid === actived.imgUid) {
            item.arc = param.arc
          }
        })
      }
      // this.$emit("real");
    },
    // 设置dpi
    setDpi(id, dpi, className) {
      const editArr = this.editArr
      for (let i = 0; i < editArr.length; i++) {
        for (let j = 0; j < editArr[i].arr.length; j++) {
          if (id === editArr[i].arr[j]['imgUid']) {
            editArr[i].arr[j]['dpi'] = dpi
            editArr[i].arr[j]['dpiClass'] = className
          }
        }
      }
      this.editArr = editArr
    },
    // 删除arr
    spliceEditArr(e) {
      const editArr = this.editArr
      for (let i = 0; i < editArr.length; i++) {
        for (let j = 0; j < editArr[i].arr.length; j++) {
          if (e.target.imgUid === editArr[i].arr[j]['imgUid']) {
            editArr[i].arr.splice(j, 1)
            break
          }
        }
      }
      this.editArr = editArr
    },
    // 添加arr
    unshiftEditArr(param) {
      this.initEditArr()
      let count = -1
      const para = {
        knifeIndex: '',
        arr: []
      }
      const knifeIndex = this.knifeIndex
      if (this.editArr.length > 0) {
        for (let i = 0; i < this.editArr.length; i++) {
          if (this.editArr[i].knifeIndex === knifeIndex) {
            count = i
            break
          }
        }
        if (count > -1) {
          this.editArr[count].arr.unshift(param)
        } else {
          para.knifeIndex = knifeIndex
          para.arr.push(param)
          this.editArr.unshift(para)
        }
      } else {
        para.knifeIndex = knifeIndex
        para.arr.push(param)
        this.editArr.push(para)
      }
    },
    // 初始化Arr
    initEditArr() {
      const editArr = this.editArr
      // const knifeIndex = this.knifeIndex;
      if (editArr.length > 0) {
        for (let i = 0; i < editArr.length; i++) {
          for (let j = 0; j < editArr[i].arr.length; j++) {
            editArr[i].arr[j].isShow = false
          }
        }
      } else {
        for (let t = 0; t < this.knifeLists.length; t++) {
          const para = {
            knifeIndex: t,
            arr: []
          }
          editArr.push(para)
        }
      }
      this.editArr = editArr
    },
    setObject(imgUid) {
      const editArr = this.editArr
      for (let i = 0; i < editArr.length; i++) {
        for (let j = 0; j < editArr[i].arr.length; j++) {
          if (imgUid === editArr[i].arr[j].imgUid) {
            editArr[i].arr[j].isShow = true
          } else {
            editArr[i].arr[j].isShow = false
          }
        }
      }
      this.editArr = editArr
      this.$forceUpdate()
    },
    // 删除
    removeCanvasObject(id, index, index2) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const objectArr = canvas.getObjects()
      this.editArr[index].arr.splice(index2, 1)
      for (let i = 0; i < objectArr.length; i++) {
        if (objectArr[i]['imgUid'] === id) {
          canvas.remove(objectArr[i])
          break
        }
      }
      if (canvas.getObjects.length === 0) {
        this.isShowVariants = false
      }
    },
    // 选择图层
    choiceObject(item, index, index2) {
      if (item.isFont) {
        this.$parent.isEditFont = true
      } else {
        this.$parent.isEditFont = false
      }
      const canvas = this.canvas[this.knifeIndex].canvas
      this.initEditArr()
      this.editArr[index].arr[index2].isShow = true
      // //数组交换位置
      // tempArray(this.editArr[index].arr, 0, index2);

      const objectArr = canvas.getObjects()
      for (let i = 0; i < objectArr.length; i++) {
        if (item.imgUid === objectArr[i]['imgUid']) {
          // item.isShow = true;
          canvas.setActiveObject(objectArr[i])
          // canvas.bringToFront(objectArr[i]);
          canvas.renderAll()
          break
        }
      }
    },
    // 隐藏图层
    handVisible(eyes, uid) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const objectArr = canvas.getObjects()
      for (let i = 0; i < objectArr.length; i++) {
        if (objectArr[i].imgUid === uid) {
          objectArr[i].visible = !objectArr[i].visible
          canvas.renderAll()
          break
        }
      }
      const editArr = this.editArr
      const knifeIndex = this.knifeIndex
      for (let i = 0; i < editArr[knifeIndex].arr.length; i++) {
        if (editArr[knifeIndex].arr[i].imgUid === uid) {
          this.editArr[knifeIndex].arr[i].isEyes = !this.editArr[knifeIndex]
            .arr[i].isEyes
        }
      }
    },
    // 设置层级
    setForward(val, id, index, index2) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const arr = canvas.getObjects()
      let actived = ''
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].imgUid === id) {
          actived = arr[i]
          break
        }
      }
      if (val === 'bringForward') {
        if (index2 > 0) {
          tempArray(this.editArr[index].arr, index2 - 1, index2)
        }
        canvas.bringForward(actived)
      } else {
        const len = this.editArr[index].arr.length - 1
        if (len >= index2) {
          tempArray(this.editArr[index].arr, index2 + 1, index2)
        }
        canvas.sendBackwards(actived)
      }
      canvas.renderAll()
    },
    // 对齐方式,得先选中图层
    align(align, sizeHeight) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      if (typeof actived !== 'undefined') {
        if (align === 'top') {
          actived.set('top', parseInt(0, 10)).setCoords()
        } else if (align === 'bottom') {
          const bottom = this.canvasHeight - sizeHeight
          actived.set('top', parseInt(bottom, 10)).setCoords()
        }
        // t.center();    //全部居中
        // t.centerH();   //水平居中
        // t.centerV();   //垂直居中
        canvas.renderAll()
        this.$emit('real')
      }
    },
    changFont(val) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      actived.text = val
      canvas.renderAll()
      this.$emit('real')
    },
    // 更改编辑图层坐标
    changePositionX(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      actived.set('left', parseInt(currentValue, 10)).setCoords()
      this.posiX = actived.left
      canvas.renderAll()
      // 实时预览
      this.$emit('real')
    },
    // 更改编辑图层坐标
    changePositionY(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      actived.set('top', parseInt(currentValue, 10)).setCoords()
      this.posiY = actived.top
      canvas.renderAll()
      // 实时预览
      this.$emit('real')
    },
    changeHeight(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      const dpi = this.$parent.countDPI(actived.width, 2) // 1还没经过变化 2经过变化
      this.setDpi(actived.imgUid, dpi.dpi, dpi.dpiClass)
      actived.scaleToHeight(currentValue)
      canvas.renderAll()
      // 实时预览
      this.$emit('real')
    },
    changeWidth(currentValue) {
      const canvas = this.canvas[this.knifeIndex].canvas
      const actived = canvas.getActiveObject()
      const dpi = this.$parent.countDPI(actived.width, 2) // 1还没经过变化 2经过变化
      this.setDpi(actived.imgUid, dpi.dpi, dpi.dpiClass)
      actived.scaleToWidth(currentValue)
      canvas.renderAll()
      // 实时预览
      this.$emit('real')
    },
    // 文件列表（分页）
    async filePage_fun() {
      this.imgLoading = true
      const { data, code, msg } = await filePage({
        current: this.page.current,
        size: this.page.size,
        fileType: this.page.fileType
      })
      this.$emit('update:loading', false)
      if (code === 200) {
        this.imgLoading = false
        if (data.records) {
          this.imgList.push(...data.records)
        }
      } else {
        this.$message.error(msg)
      }
      this.$emit('update:submitAwait', false)
    },
    // 删除文件
    async deleteFile(id) {
      this.$emit('update:loading', true)
      this.page.current = 1
      this.imgList = []
      const { code } = await delFile({ fileId: id })
      if (code === 200) {
        this.filePage_fun()
      }
    },
    // 添加文件
    add(id, item) {
      // if (!this.imgLodingFinsh.includes(id)) {
      //   return;
      // }
      var _this = this
      this.$parent.isShowAddImgDialog = false
      const scaling = this.scaling
      const canvasHeight = this.windowsWidth
      const knifeIndex = this.knifeIndex
      const canvas = _this.canvas[knifeIndex]['canvas']
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = item.fileUrl
      this.$parent.imgUid = this.$parent.imgUid + 1
      this.initEditArr()
      this.$emit('update:submitAwait', true)
      image.onload = function() {
        _this.$emit('update:submitAwait', false)
        const imgWidth = image.width
        const imgHeight = image.height
        let proportion = 0
        // 图片小于画布大小
        if (imgWidth < canvasHeight && imgHeight < canvasHeight) {
          proportion = _this.scaling
        } else if (imgWidth < canvasHeight) {
          if (imgWidth > imgHeight) {
            proportion = canvasHeight / imgWidth
          } else {
            proportion = canvasHeight / imgHeight
          }
        } else {
          if (imgWidth > imgHeight) {
            proportion = canvasHeight / imgWidth
          } else {
            proportion = canvasHeight / imgHeight
          }
        }
        // 图片加载完，再draw 和 toDataURL
        const imgInstance = new fabric.Image(image, {
          isShow: false,
          propWidth: image.width * scaling,
          imgUid: _this.$parent.imgUid,
          fileUrl: item.fileUrl,
          fileName: item.fileName,
          crossOrigin: 'Anonymous',
          angle: 0, // 图片旋转角度
          opacity: 1, // 图片透明度
          // 这里可以通过scaleX和scaleY来设置图片绘制后的大小,这里为原来大小的一半
          scaleX: proportion,
          scaleY: proportion,
          hasRotatingPoint: false
        })
        imgInstance.setCrossOrigin('Anonymous')
        canvas.add(imgInstance)
        const arr = canvas.getObjects()
        canvas.setActiveObject(arr[arr.length - 1])
        const actived = canvas.getActiveObject()
        canvas.bringToFront(actived) // 向上跳顶层
        canvas.preserveObjectStacking = true
        // 居中显示
        actived.center().setCoords()
        canvas.renderAll()
        _this.$parent.KnifePreview()
        const dpi = _this.$parent.countDPI(image.width, 1) // 1还没经过变化 2经过变化
        const param = {}
        param.posiX = actived.left
        param.posiY = actived.top
        param.isShow = true
        param.isFont = false // 字体还是图片
        param.sizeWidth = image.width * scaling // 自适应后的图片宽度
        param.sizeHeight = image.height * scaling // 自适应后的图片宽度
        param.scale = actived.scaleY // 缩放比例
        param.rotate = actived.angle // 旋转角度
        param.isEyes = true // 是否可见
        param.imgUid = _this.$parent.imgUid
        param.id = item.id
        param.fileUrl = item.fileUrl
        param.fileName = item.fileName
        param.dpi = dpi.dpi
        param.dpiClass = dpi.dpiClass
        param.knifeIndex = knifeIndex
        _this.unshiftEditArr(param)
      }
    },
    // 文件上传
    async fnUploadRequest(option) {
      if (option.file.size > 80 * 1024 * 1024) {
        this.$notification.error({ message: 'Maximum file size 80 MB' })
        return
      }
      this.$emit('update:loading', true)
      try {
        const _this = this

        const fileSize = getFileSize(option.file.size)
        // 获取OSS配置信息
        const client = new OSS({
          endpoint: this.ossData.ossEndpoint,
          accessKeyId: this.ossData.accessKeyId,
          accessKeySecret: this.ossData.accessKeySecret,
          bucket: this.ossData.bucket,
          stsToken: this.ossData.securityToken
        })

        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const uuidValue = uuid()
        const fileNames = `${this.ossData.ossObjectNamePrefix}${uuidValue}${suffix}`
        // 分片上传文件
        const res = await client.multipartUpload(fileNames, file, {
          progress: async function(p) {
            console.log(p)
            console.log('分片。。。。。')
          },
          headers: {
            'Cache-Control': 'public, max-age=360000'
          }
        })
        if (res.res.statusCode === 200) {
          const imageUrl = this.ossData.domain + '/' + fileNames
          var reader = new FileReader()
          reader.onload = function(evt) {
            var image = new Image()
            image.onload = function() {
              _this.submitFile(
                fileSize,
                fileName,
                image.width,
                image.height,
                imageUrl,
                res.bucket,
                res.name
              )
            }
            image.src = evt.target.result
          }
          reader.readAsDataURL(file)
        } else {
          this.$message.error('Image upload failed due to unstable network')
        }
      } catch (error) {
        this.$message.error('Image upload failed due to unstable network')
      }
    },
    async submitFile(
      fileSize,
      fileName,
      width,
      height,
      fileUrl,
      bucketName,
      objectName
    ) {
      const { code } = await submitOssFile({
        fileSize: fileSize,
        fileName: fileName,
        fileType: 1,
        width: width,
        height: height,
        fileUrl: fileUrl,
        bucketName: bucketName,
        objectName: objectName
      })
      if (code === 200) {
        this.$emit('update:loading', false)
        // 图片列表
        this.page.current = 1
        this.imgList = []
        this.filePage_fun()
      }
    },
    closAddDialog() {
      this.$emit('closAddDialog')
    }
  }
}
</script>
<style scoped lang="scss">
.add-bgcolor {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  input {
    width: 30px;
    height: 30px;
  }
  span {
    margin-left: 10px;
  }
  .ant-checkbox-wrapper {
    margin-left: auto;
    margin-top: 0;
  }
}
.foru-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-y: auto;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  padding: 50px;
  background: rgba(0, 0, 0, 0.5);
  .img-box {
    width: 66.666%;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    background: white;
    display: flex;
    .icon-close-con {
      position: absolute;
      top: 0px;
      right: -30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: white;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      .icon-close {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        margin-top: 7px;
      }
    }
    .imgbox {
      flex: 1;
      .img-add-normal {
        margin: 0 30px;
        height: 390px;
        border: 1px dashed #e6e6e6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .img-add-normal-box {
          text-align: center;
          p {
            margin: 0;
            font-size: 16px;
          }
          img {
            width: 100px;
            height: 79px;
          }
        }
      }
      .img-link {
        font-size: 16px;
        margin: 0 30px;
        margin-top: 16px;
        padding-bottom: 55px;
        a {
          color: #2260b5;
        }
      }
      .imgBox-con {
        position: relative;
        padding: 26px 30px;
        background: #fff;
        button {
          font-size: 16px;
          padding: 0 111px;
          height: 40px;
        }
        p {
          text-align: left;
          color: #000;
        }
      }
    }
    .img-li {
      width: 68%;
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 35px;
        border-left: 1px solid #e6e6e6;
        overflow-y: scroll;
        li {
          margin-right: 23px;
          margin-top: 26px;
          img {
            width: 184px;
            height: 184px;
          }
          p {
            margin: 0;
          }
          .img-name {
            width: 184px;
            margin-top: 12px;
            font-size: 14px;
            color: #262626;
          }
          .img-desc {
            font-size: 12px;
            color: #808080;
          }
        }
      }
    }
  }

  .upload-button .upload-button__button {
    width: 159px;
    height: 36px;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 34px;
  }
  .btn-02 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.2s ease;
  }
}
.img-edit {
  section {
    .t-content {
      overflow: auto;
      max-height: 700px;
      margin: 0 10px;
      .t-list {
        height: 70px;
        border: 1px solid #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        margin-top: 15px;
        cursor: pointer;
        .icon-del {
          width: 22px;
          height: 22px;
        }
        img {
          width: 55px;
          height: 55px;
          object-fit: contain;
          object-position: center;
          border: 1px solid #e7e7e7;
          margin-right: 22px;
        }
        .t-msg {
          flex: 1;
          max-width: 243px;
          padding: 0 15px;
          box-sizing: border-box;
          text-align: left;
          p {
            margin: 0;
            padding: 0;
          }
        }
        .t-size {
          font-size: 14px;
          color: rgba(56, 56, 56, 0.6);
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.img-li li img {
  object-fit: contain;
  object-position: center;
}
.imgcontent {
  background: white;
  height: 302px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  /*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: white;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  /*滚动条的设置*/

  &::-webkit-scrollbar-thumb {
    background-color: #606d71;
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  /*滚动条移上去的背景*/

  &::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
  &:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.th-contents {
  .edit-font {
    margin-top: 15px;
  }
  .th-con {
    border-bottom: 1px solid #e6e6e6;
    .th-list {
      height: 80px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        object-position: center;
        margin-left: 20px;
        margin-right: 12px;
      }
      .icon-del {
        width: 22px;
        height: 22px;
        margin-right: 20px;
        img {
          width: 22px;
          height: 22px;
        }
      }
      .th-msg {
        flex: 1;
        max-width: 226px;
        padding-right: 15px;
        text-align: left;
        height: 60px;
        p {
          margin: 0;
        }
        .th-name {
          width: 120px;
          font-size: 14px;
        }
        .th-size {
          padding: 4px 7px;
          border-radius: 4px;
          display: inline-block;
          font-size: 12px;
          margin-top: 5px;
        }
      }
      .th-size {
        font-size: 14px;
        color: rgba(56, 56, 56, 0.6);
        margin-top: 10px;
      }
      .th-goods {
        color: #5aac50;
        background: #d9ecda;
      }
      .th-normal {
        background: #fff5bb;
        color: #b68d00;
      }
      .th-bad {
        color: #ea5454;
        background: #ffebeb;
      }
      .th-blue {
        color: #2260b5;
        background-color: #2260b533 !important;
      }
      i {
        font-size: 20px;
      }
    }
    .th-active-bg {
      background: #f7f7f7;
    }
    .th-change {
      margin: 0 20px;
      padding-bottom: 17px;
      .th-change-head {
        display: flex;
        padding-top: 14px;
        span {
          font-size: 14px;
          font-weight: bold;
          flex: 1;
        }
      }
      .th-change-con {
        margin-top: 8px;
        display: flex;
        .th-change-flex {
          display: flex;
          flex: 1;
          .th-change-box {
            width: 32px;
            height: 32px;
            border: 1px solid #e6e6e6;
            margin-right: 10px;
            img {
              width: 16px;
              height: 16px;
              margin-top: 8px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
.th-item {
  .th-item-list {
    width: 50%;
    float: left;
    p {
      text-align: left;
      font-size: 14px;
      color: rgba(56, 56, 56, 0.6);
      margin-top: 10px;
    }
  }
}
.th-align {
  margin-top: 10px;
  text-align: left;
  img {
    border: 1px solid #e7e7e7;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    margin-top: 10px;
    float: left;
    margin-right: 15px;
  }
}
.submit {
  font-size: 24px;
  border: none;
  width: 200px;
  height: 50px;
  background-color: #4ccbd7;
  color: #fff;
  line-height: 50px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
</style>
